import loggingJitsuApi from "../api/logging/loggingJitsu.api";
import { isDeviceMobile } from "../app/helpers/commonFunctions";
import { ActionDimensions } from "./dimensions/ActionDimensions";
import { GeoDimensions } from "./dimensions/GeoDimensions";
import { SessionDimensions } from "./dimensions/SessionDimensions";
import { TechDimensions } from "./dimensions/TechDimensions";
import { UserDimensions } from "./dimensions/UserDimensions";
import { updateActionHistory } from "./helpers/updateActionHistory";
import { DEFAULT_VALUE, EnumActionNameLevel1, EnumElementName, EnumGeneratedBy, EnumUxTypes, ILoggingAPIBody, Post } from "./types/LoggingTypes";

interface SentDimensions {
    uxType: EnumUxTypes,
    actionGeneratedBy: EnumGeneratedBy,
    action_reference_entity?: string,
    action_reference_id?: number,
    userTargetId?: string,
    post?: Post,
    actionElementName?: EnumElementName
}

const
    sendLoggingInformation = async (
        sentDimensions: SentDimensions | SentDimensions[]
    ) => {
        let sentDimensionsArr: SentDimensions[] = Array.isArray(sentDimensions) ? sentDimensions : [sentDimensions];
        const payloads: ILoggingAPIBody[] = [];

        await Promise.all(sentDimensionsArr.map(sentDimensions => {
            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    const geoDimensions = GeoDimensions.getInstance();
                    const techDimensions = TechDimensions.getInstance();
                    const userDimensions = UserDimensions.getInstance();
                    const sessionDimensions = SessionDimensions.getInstance();
                    const actionDimensions = ActionDimensions.getInstance();
                    const {
                        uxType,
                        actionGeneratedBy,
                        action_reference_entity,
                        action_reference_id,
                        post,
                        userTargetId,
                        actionElementName
                    } = sentDimensions;

                    if (post) {
                        if (post.country) geoDimensions.geoPostCountry = post.country;
                        if (post.neighborhoodName) geoDimensions.geoPostNeighborhood = post.neighborhoodName;
                        if (post.city && post.city.lat && post.city.lng) geoDimensions.geoPostLocation = {
                            latitude: post.city.lat.toString() ?? DEFAULT_VALUE,
                            longitude: post.city.lng.toString() ?? DEFAULT_VALUE
                        };
                        if (post.city && post.city.name) geoDimensions.geoPostCity = post.city.name || DEFAULT_VALUE;
                        if (post.id) actionDimensions.actionListingId = post.id.toString();
                        if (post.category && post.category.label) actionDimensions.actionListingCategory = post.category.label;
                        if (post.subCategory) actionDimensions.actionListingSubCategory = post.subCategory;
                        if (post.service && post.service.length > 0) actionDimensions.actionListingPaidFeatures = post.service;
                        if (post.listingStatus) actionDimensions.actionListingVasLevel = post.listingStatus;
                    }

                    userDimensions.userTargetId = userTargetId ?? DEFAULT_VALUE;
                    actionDimensions.actionReferenceEntity = action_reference_entity ?? DEFAULT_VALUE;
                    actionDimensions.actionReferenceId = action_reference_id ? action_reference_id.toString() : DEFAULT_VALUE;
                    actionDimensions.uxType = uxType.toString();
                    actionDimensions.actionGeneratedBy = actionGeneratedBy.toString();
                    actionDimensions.actionElementName = actionElementName ? actionElementName : DEFAULT_VALUE;
                    techDimensions.platform = isDeviceMobile() ? 'web_mobile' : 'web_desktop';

                    const body: ILoggingAPIBody = {
                        Geo_user_country: geoDimensions.geoUserCountry,
                        Geo_user_city: geoDimensions.geoUserCity,
                        Geo_user_location: geoDimensions.geoUserLocation.latitude !== DEFAULT_VALUE && geoDimensions.geoUserLocation.longitude !== DEFAULT_VALUE ? `${geoDimensions.geoUserLocation.latitude},${geoDimensions.geoUserLocation.longitude}` : DEFAULT_VALUE,
                        Geo_app_country: geoDimensions.geoAppCountry,
                        Geo_post_country: geoDimensions.geoPostCountry,
                        Geo_post_city: geoDimensions.geoPostCity,
                        Geo_post_neighborhood: geoDimensions.geoPostNeighborhood,
                        Geo_post_location: geoDimensions.geoPostLocation.latitude !== DEFAULT_VALUE && geoDimensions.geoPostLocation.longitude !== DEFAULT_VALUE ? `${geoDimensions.geoPostLocation.latitude},${geoDimensions.geoPostLocation.longitude}` : DEFAULT_VALUE,

                        Tech_device_brand: techDimensions.deviceBrand,
                        Tech_device_model: techDimensions.deviceModel,
                        Tech_device_screen_resolution: techDimensions.screenResolution,
                        Tech_device_os: techDimensions.deviceOS,
                        Tech_device_os_version: techDimensions.deviceOSVersion,
                        Tech_device_browser: techDimensions.deviceBrowser,
                        Tech_device_browser_version: techDimensions.deviceBrowserVersion,
                        Tech_device_language: techDimensions.deviceLanguage,
                        Tech_device_timezone: techDimensions.deviceTimeZone,
                        Tech_device_user_agent: techDimensions.deviceUserAgent,
                        Tech_device_cpu_arch: techDimensions.deviceCpuArch,
                        Tech_http_referrer: techDimensions.httpReferrer,
                        Tech_app_version: techDimensions.version,
                        Tech_app_language: techDimensions.language,
                        Tech_app_platform: techDimensions.platform,
                        Tech_app_ui_theme: techDimensions.deviceAppUITheme,
                        Tech_connection_type: techDimensions.connectionType,
                        Tech_connection_latency: techDimensions.deviceRTT,

                        User_abbucket: userDimensions.userAbbucket,
                        User_member_id: userDimensions.userMemberId,
                        User_tracking_id: userDimensions.userTrackingId,
                        User_type: /*userDimensions.userType*/ "##MISSING##",
                        User_authentication: userDimensions.userAuth,
                        User_target_id: userDimensions.userTargetId,
                        User_target_type: /*userDimensions.userTargetType*/ "##MISSING##",

                        Session_id: sessionDimensions.sessionId,
                        Session_search_id: sessionDimensions.sessionSearchId,
                        Session_source: sessionDimensions.sessionSource,
                        Session_medium: sessionDimensions.sessionMedium,
                        Session_campaign: sessionDimensions.sessionCampaign,
                        Session_life: sessionDimensions.sessionLife,

                        Action_screen_name: actionDimensions.currScreen,
                        Action_screen_name_previous: actionDimensions.prevScreen,
                        Action_widget_name: actionDimensions.currWidgetName,
                        Action_ux_type: actionDimensions.uxType,
                        Action_name: actionDimensions.actionName.L1,
                        Action_name_lvl2: actionDimensions.actionName.L2,
                        Action_name_lvl3: actionDimensions.actionName.L3,
                        Action_widget_name_previous: actionDimensions.prevWidgetName,
                        Action_name_previous: actionDimensions.prevActionName.L1,
                        Action_name_lvl2_previous: actionDimensions.prevActionName.L2,
                        Action_name_lvl3_previous: actionDimensions.prevActionName.L3,
                        Action_listing_id: actionDimensions.actionListingId,
                        Action_listing_status: actionDimensions.actionListingStatus,
                        Action_listing_category: actionDimensions.actionListingCategory,
                        Action_listing_subcategory: actionDimensions.actionListingSubCategory,
                        Action_listing_paid_features: actionDimensions.actionListingPaidFeatures,
                        Action_listing_cell_type: actionDimensions.actionListingCellType,
                        Action_listing_origin_slot_type: /*actionDimensions.actionListingOriginSlotType*/ "##MISSING##",
                        Action_reference_id: actionDimensions.actionReferenceId,
                        Action_reference_entity: actionDimensions.actionReferenceEntity,
                        Action_generated_by: actionDimensions.actionGeneratedBy,
                        Action_element_name: actionDimensions.actionElementName,
                        Action_listing_vas_level: actionDimensions.actionListingVasLevel,

                        filter_category: actionDimensions.filterCategory,
                        filter_subcategory: actionDimensions.filterSubcategory,
                        filter_listing: actionDimensions.filterListing,
                        filter_member: actionDimensions.filterMember,
                        filter_keyword: actionDimensions.filterKeyword,
                        filter_cp: actionDimensions.filterCP,

                        Session_funnel_source: (actionDimensions.actionName.L1 === EnumActionNameLevel1.ADD || actionDimensions.actionName.L1 === EnumActionNameLevel1.EDIT || actionDimensions.actionName.L1 === EnumActionNameLevel1.ORDER || actionDimensions.actionName.L1 === EnumActionNameLevel1.AUTHENTICATE)
                            ? actionDimensions.sessionFunnelSource : DEFAULT_VALUE,
                        Session_funnel_point_of_entry: actionDimensions.sessionFunnelPointOfEntry,
                        Tech_error_code: null,
                        Search_id: DEFAULT_VALUE,
                        Search_key: DEFAULT_VALUE,
                        Search_page: DEFAULT_VALUE,
                        Search_total_results: DEFAULT_VALUE,
                        Search_position: DEFAULT_VALUE,
                        Search_terms: DEFAULT_VALUE,
                    };

                    payloads.push(body);
                    updateActionHistory(actionDimensions.actionName.L1, actionDimensions.actionName.L2, actionDimensions.actionName.L3);

                    // Reset dimensions
                    actionDimensions.reset();
                    geoDimensions.reset();
                    userDimensions.reset();

                    resolve(); // Resolve the promise
                }, 0);
            });
        }));

        // Send payloads to logging API after all setTimeouts are resolved
        loggingJitsuApi(payloads).then(res => res).catch(e => e);

    };

export {sendLoggingInformation};
