import { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { logAddListingClick } from '../../../logging/helpers/commonLoggingFunctions'
import { EnumUxTypes } from '../../../logging/types/LoggingTypes'
import { KTSVG, toAbsoluteUrl } from '../../helpers'
import { defaultOptions, extractUtms, getCookies, isDeviceMobile, setCookie } from '../../helpers/commonFunctions'
import { AuthContext } from '../../i18n/AuthProvider'
import { useLang } from '../../i18n/OpenSooqi18n'
import { MemberShipModal } from '../payment/MemberShipModal'


export default function AddNewListing({ source, forceMobile, withBorder ,pos,widgetName, isContained = true}: {source: string, forceMobile?: boolean, withBorder?: boolean,pos ?: string,widgetName?: string, isContained?: boolean}) {
    const points = [
        `notFound.${source}.point1`,
        `notFound.${source}.point2`,
        `notFound.${source}.point3`,
    ]
    const imagePath = toAbsoluteUrl(`/media/custom/${source}-not-found.png`);
    const Intl = useIntl();
    const lang = useLang();
    const [showBundle, setShowBundle] = useState(false)
    const { userIdentity } = useContext(AuthContext)

    const redirectToAddPost = async () => {
        //logging
        logAddListingClick(EnumUxTypes.BUTTON, widgetName);
        //
        setCookie("addPostRef",encodeURIComponent(window.location.href), defaultOptions);
        const link = `${process.env.REACT_APP_ADD_POST_REDIRECT_URL}?v=2&source=${getCookies().source}&lang=${lang}&flow=add&return=${window.location.href}&${extractUtms()}`;
        setTimeout(()=>{
          window.location.href = link;
        }, 200)
      }

    const handleClick = () => {
        if(source === 'shop')
        {   
            setShowBundle(true);
            return;
        }
        redirectToAddPost();
    }

    return (
        <div className={`d-flex justify-content-between align-items-center ${withBorder&& 'border'} bg-white rounded-3 ${isContained ? "container" : ""} ${!isDeviceMobile() && "px-10"} py-5 w-100`}>
            <div className='d-flex flex-column justify-content-between w-100'>
                <div><span className='h2 fs-1 fw-bold'>{Intl.formatMessage({id: `notFound.${source}.title`})}</span></div>
                {(forceMobile || isDeviceMobile()) && <div className='align-self-center justify-self-center mt-3'><img height={"170px"} width={"340px"} src={imagePath} alt='add new listing' /></div>}
                <div className='d-flex justify-content-between w-100'>
                    <div className='d-flex flex-column my-7'>
                        {points && points.map((point, index) => (
                            <div key={index} className='d-flex align-items-center gap-2 my-1'>
                                <div className='me-3' style={{width: '20px', height: '20px'}}>
                                    <KTSVG path='/media/custom/circled-green-check.svg' className='svg-icon-2'/>
                                </div>
                                <div>
                                    <span className='fs-3'>{Intl.formatMessage({id: point})}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <button type='button' data-click={source !== 'shop' && 'noListingsFound-addPost'} className={`( ${ (!isDeviceMobile() && !forceMobile) && 'fs-2'} btn btn-lg fw-bold w-300px ${source === "default"? "btn-warning": "btn-primary"} ${isDeviceMobile() && "w-100 align-self-center"}`} onClick={handleClick} data-id="home-getMoreListings-getMoreListingsBtn">{Intl.formatMessage({id: `notFound.${source}.button`})}</button>
            </div>
            {(!isDeviceMobile() && !forceMobile ) && <div><img className='d-block h-175px py-3' src={imagePath} alt='add new listing' /></div>}
            {source === 'shop' && <MemberShipModal show={showBundle} type={['MemberShip']} handleClose={() => {setShowBundle(false)}} id={userIdentity?.id} pos={pos} widgetName={'upgrade_account_card'}/>}
        </div>
  )
}
