import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import getAccountStatsApi from "../../../api/account/getAccountStats.api"
import { useLang } from "../../../app/i18n/OpenSooqi18n"
import { ListingStatsInterface } from "../../../app/interfaces/ListingStatsInterface"
import { logScreenLoad } from "../../../logging/helpers/commonLoggingFunctions"
import { generateOpenSooqRedirectUrl, getCookies, redirectionInterceptor } from "../../helpers/commonFunctions"
import { AccountChart } from "../../layout/components/opensooq/AccountChart"
import { HistoricalViews } from "./HistoricalViews"

const AccountStats = () => {
    const adId : number | string = new URLSearchParams(window.location.search).get('id') ?? '0';
    const [viewsCountTotal,setViewsCountTotal] = useState <number>(0)
    const [postCountTotal,setPostCountTotal] = useState <number>(0)
    const [cvCountTotal,setCvCountTotal] = useState <number>(0)
    const [isLoading,setISLoading] = useState <boolean>(true)
    const [posts,setPosts] = useState <ListingStatsInterface>()
    const [views,setViews] = useState <ListingStatsInterface>()
    const [cvs,setCvs] = useState <ListingStatsInterface>()
    const Intl = useIntl();
    const lang = useLang();
    useEffect(() => {
        getAccountStatsApi().then(response => {
            setPosts(response.data.posts);
            setViews(response.data.views);
            setCvs(response.data.cvs);
            setViewsCountTotal(response.data.viewsCountTotal);
            setPostCountTotal(response.data.postCountTotal);
            setCvCountTotal(response.data.cvCountTotal);
            setISLoading(false)
        });
        logScreenLoad()
    },[]);
   
    const openSooqUrl = generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE,getCookies().ecountry,lang)
    return (
        <>
            {isLoading && <>
                <div className="row p-5">
                    <div className="col-lg-12 text-center">
                        <div className="spinner-border text-success" role="status">
                            <span className="visually-hidden"></span>
                        </div>
                    </div>
                </div>
            </>}
            {!isLoading && <>
                <div className="row">
                    <div className="col-lg-6 col-12 mt-3">
                        <HistoricalViews cvCountTotal={cvCountTotal} totalAdsViews={postCountTotal} totalAccountViews={viewsCountTotal} className='card-xl-stretch mb-xl-8 h-100' color='primary' />
                    </div>
                    <div className="col-lg-6 col-12 mt-3">
                        <div className="card h-md-100 h-100">
                            <div className="card-body d-flex flex-column flex-center">
                                <div className="mb-2">
                                    <h1 className="fw-semibold text-gray-800 text-center lh-lg">
                                        {Intl.formatMessage({id: 'accountStatsText1'})}
                                        <br/>
                                        {Intl.formatMessage({id: 'accountStatsText2'})}
                                    </h1>
                                    <div className="py-10 text-center">
                                        <img src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/illustrations/easy/1.svg" className="theme-light-show w-200px" alt=""/>
                                    </div>
                                </div>
                                <div className="text-center mb-1">
                                    <a onClick={redirectionInterceptor} href={`${openSooqUrl}/advertising-products`} className="btn btn-sm btn-primary me-2" target="_blank">
                                        {Intl.formatMessage({id: 'accountStatsText3'})}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {posts &&  <div className="col-12">
                        <AccountChart className='mb-5 mt-5' total={posts.total ?? 0}  description={Intl.formatMessage({id: 'accountStateText15'})} title={Intl.formatMessage({id: 'accountStateText14'})} chartType={posts} target={'test'} chartName={Intl.formatMessage({id: 'accountStateText20'})} adId={adId}/>
                    </div>}
                    {views &&  <div className="col-12">
                        <AccountChart className='mb-5 mt-5' total={views.total ?? 0} description={Intl.formatMessage({id: 'accountStateText17'})} title={Intl.formatMessage({id: 'accountStateText16'})} chartType={views} target={'test'} chartName={Intl.formatMessage({id: 'accountStateText20'})} adId={adId}/>
                    </div>}
                    {cvs &&  <div className="col-12">
                        <AccountChart className='mb-5 mt-5' total={cvs.total ?? 0}  description={Intl.formatMessage({id : "accountStateText19"})} title={Intl.formatMessage({id : "accountStatsText9"})} chartType={cvs} target={'test'} adId={adId} chartName={Intl.formatMessage({id: 'accountStateText20'})}/>
                    </div>}
                </div>
            </>
            }
            
        </>
)}

export { AccountStats }

