import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLang } from "../../../app/i18n/OpenSooqi18n";
import { logScreenLoad } from "../../../logging/helpers/commonLoggingFunctions";
import { extractUtms, getCookies, logout } from "../../helpers/commonFunctions";
import { IframeLoader } from "../common/IframeLoader";

export function ActivateListing() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const listingId = params.get('id') ?? '';
    const [loader,setLoader] = useState <boolean>(true)
    const iframeContent = useRef<null | HTMLIFrameElement>(null);
    const lang = useLang();
    window.addEventListener("message", event => {
        if(event.data === 401){
           logout()
        }else if(event.data == 200){
            setLoader(false);
            if(iframeContent.current){
                iframeContent.current.classList.remove('d-none')
            }
        }
    }, false);

    useEffect(()=>{
        logScreenLoad()
    }, [])

    return (
        <div className='text-center'>
            {loader && <IframeLoader/>}
            <iframe src={`${process.env.REACT_APP_ADD_POST}?v=2&source=${getCookies().source}&lang=${lang}&flow=activate&post_id=${listingId}&from=my-opensooq&${extractUtms()}`} ref={iframeContent} className="d-none w-100" style={{height : "100%",minHeight: isMobile ? '100%' : '90vh',overflowY:"hidden"}}></iframe>
        </div>
    )
  }