import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {
  generateOpenSooqRedirectUrl,
  getCookies,
  getNoImageLogo,
} from '../../../../helpers/commonFunctions'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import {generateStyleClasses, styleHelper} from '../helper'
import shortUrlApi from '../../../../../api/site/shortUrl.api'
import {useEffect, useState} from 'react'
import {KTSVG} from '../../../../helpers'
import Skeleton from 'react-loading-skeleton'
import {showSuccess} from '../../../../helper/SystemAlert'
import {FacebookShareButton, TwitterShareButton} from 'react-share'
import shareLogging from '../../../../../api/shareLogging'
import {ShareButtonType, ShareLoggingScreens} from '../../../../helper/ShareLogging'

type Props = {
  show: boolean
  handleClose: () => void
  selectedListing: any
  extraClass?: string
}

const ListingSharePopup = ({show, handleClose, selectedListing, extraClass}: Props) => {
  const Intl = useIntl()
  const lang = useLang()
  const cookies = getCookies()
  const [shortUrl, setShortUrl] = useState<string>('')
  const handleImage = (imgUri) => {
    if (!imgUri) return getNoImageLogo(lang)
    return `${process.env.REACT_APP_ARBOK_UPLOAD}${imgUri}`
  }

  const shorten = (data) => {
    shortUrlApi(data).then((response) => {
      setShortUrl(response.data.links.url)
    })
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(shortUrl)
    showSuccess(Intl, lang, Intl.formatMessage({id: 'copiedSuccess'}))
  }

  const handleShareLogging = (type: string) => {
    shareLogging({
      Logging: {
        model: 'Post',
        model_id: selectedListing?.listingId,
        share_to: type,
        data: ShareLoggingScreens.MY_Listings_List,
      },
    })
  }

  useEffect(() => {
    const url =
      generateOpenSooqRedirectUrl(
        process.env.REACT_APP_OPENSOOQ_BASE ?? '',
        cookies.ecountry ?? 'jo',
        lang
      ) +
      '/search/' +
      selectedListing?.listingId +
      '/' +
      selectedListing?.title.text.replace(/\s+/g, '-')
    const data = {
      links: {
        url,
      },
    }
    shorten(data)
  }, [selectedListing])

  return (
    <Modal
      id='iframe'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName={`modal-dialog modal-dialog-centered listing-share-popup ${extraClass}`}
      show={show}
      onHide={handleClose}
    >
      <div className='modal-header p-7'>
        <div className='d-flex flex-column'>
          <span className='text-28 fw-bold'>
            {Intl.formatMessage({id: 'mylistings.share.listing.title'})}
          </span>
          <span className='text-20 text-muted'>
            {Intl.formatMessage({id: 'mylistings.share.listing.subTitle'})}
          </span>
        </div>
      </div>

      <div className='modal-body d-flex flex-column gap-5'>
        <div className='d-flex gap-2 border rounded-3 p-3'>
          <img
            src={handleImage(selectedListing.listingImage)}
            width={80}
            height={80}
            className='rounded-3'
            alt=''
          />
          <div className='d-flex flex-column gap-1 w-75'>
            <span
              id='myListingSnippet'
              style={styleHelper({
                color: selectedListing.title?.textColor,
              })}
              className={`${generateStyleClasses(selectedListing.title?.style)}  text-truncate`}
            >
              {selectedListing.title.text}
            </span>
            <span className='customPostTitleText' style={{height: '40px', lineHeight: '1.6'}}>
              {selectedListing.strs}
            </span>
            <span
              style={styleHelper({
                color: selectedListing.price?.textColor,
              })}
              className={`${generateStyleClasses(selectedListing.price?.style)}`}
            >
              {selectedListing.price?.text}
            </span>
          </div>
        </div>

        <div
          className='d-flex justify-content-between border rounded-3 p-3 align-items-center w-100 flex-wrap gap-3'
          style={{
            background: '#F4F6FF',
          }}
        >
          <div className='d-flex flex-column'>
            <span className='fs-4 fw-bold'>
              {Intl.formatMessage({id: 'mylistings.share.listing.share'})}
            </span>
            <span className='fs-4 text-muted'>
              {Intl.formatMessage({id: 'mylistings.share.listing.share.war'})}
            </span>
          </div>

          <button
            className='btn fw-bold text-white h-75 d-flex align-items-center'
            style={{background: '#4A68FF'}}
            onClick={copyToClipboard}
          >
            <img
              src='https://opensooqui-new.s3.amazonaws.com/os_web/myopensooq/copy_link.png'
              alt='copy_listing'
              width={16}
              height={16}
              className=''
            />
            <span className='mx-2'>
              {Intl.formatMessage({id: 'mylistings.share.listing.copyLink'})}
            </span>
          </button>
        </div>
        <div
          className='d-flex gap-2 rounded-3 p-3 share-link-shadow hoverPointer'
          style={{
            border: '1px solid #96A7FF',
          }}
          onClick={copyToClipboard}
        >
          {!shortUrl && <Skeleton width={180} height={20} />}
          {shortUrl && <div className='d-flex justify-content-between w-100'>{shortUrl ?? ''}</div>}
        </div>

        <div>
          <span
            className={`fs-6 d-block lined_text line_before_text line_after_text fw-normal w-100`}
          >
            {Intl.formatMessage({id: 'mylistings.share.listing.or'})}
          </span>
        </div>

        <div className='d-flex gap-2 flex-column align-items-center'>
          <span className='fs-4 fw-bold mb-2'>
            {Intl.formatMessage({id: 'mylistings.share.listing.publish'})}
          </span>
          <FacebookShareButton
            url={shortUrl}
            onClick={() => handleShareLogging(ShareButtonType.FACEBOOK)}
          >
            <button className='btn border rounded-3 d-flex' style={{width: '255px'}}>
              <KTSVG className='svg-icon-2 ms-3' path='/media/svg/general/facebook-new.svg' />

              <span className='mx-2 ms-3'>
                {Intl.formatMessage({id: 'mylistings.share.listing.publish.facebook'})}
              </span>
            </button>
          </FacebookShareButton>
          <button className='btn border rounded-3 d-flex' style={{width: '255px'}}>
            <KTSVG className='svg-icon-2 ms-4' path='/media/svg/general/instagram-new.svg' />
            <span className='mx-2'>
              {Intl.formatMessage({id: 'mylistings.share.listing.publish.instagram'})}
            </span>
          </button>
          <TwitterShareButton
            url={shortUrl}
            onClick={() => handleShareLogging(ShareButtonType.TWITTER)}
          >
            <button className='btn border rounded-3 d-flex' style={{width: '255px'}}>
              <KTSVG className='svg-icon-3 ms-4' path='/media/svg/general/x-new.svg' />
              <span className='mx-2'>
                {Intl.formatMessage({id: 'mylistings.share.listing.publish.x'})}
              </span>
            </button>
          </TwitterShareButton>
        </div>
      </div>

      <div className='modal-footer d-flex justify-content-between p-4'>
        <button className='btn btn-lg border rounded-3' onClick={handleClose}>
          {Intl.formatMessage({id: 'mylistings.share.listing.cancel'})}
        </button>
        {/* <button className='btn btn-lg border rounded-3 text-primary' onClick={handleClose}>
          {Intl.formatMessage({id: 'mylistings.share.listing.done'})}
        </button> */}
      </div>
    </Modal>
  )
}

export {ListingSharePopup}

// "mylistings.share.listing.publish": "Publish your listing Via the following",
// "mylistings.share.listing.publish.facebook": "Publish on Facebook",
// "mylistings.share.listing.publish.instagram": "Publish on Instagram",
// "mylistings.share.listing.publish.x": "Publish on X",
// "mylistings.share.listing.done": "Done",
// "mylistings.share.listing.cancel": "Cancel"
