import Cookies from 'js-cookie'
import DynamicObject from '../../../../models/dynamic-object'
import {MEMBER_COUNTRY_COOKIE, VIEWED_LISTING_COOKIE} from '../../../helpers/CookiesVariables'
import {getCookies} from '../../../helpers/commonFunctions'

const JOB_POSTER_TYPE = 'JobPoster'
const JOB_SEEKER_TYPE = 'JobSeeker'

const formatNumber = (n: number) => {
  var parts = n.toString().split('.')
  const numberPart = parts[0]
  const decimalPart = parts[1]
  const thousands = /\B(?=(\d{3})+(?!\d))/g
  return numberPart.replace(thousands, ',') + (decimalPart ? '.' + decimalPart : '')
}

const checkIfToday = (date: number) => {
  const inputDate = new Date(date)
  const today = new Date()
  return today.toDateString() == inputDate.toDateString()
}

const checkIfWeek = (date: number) => {
  const inputDate = new Date(date)
  const day = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
  return inputDate.toDateString() > day.toDateString()
}

const checkIfMonth = (date: number) => {
  const inputDate = new Date(date)
  const today = new Date()
  return today.getMonth() == inputDate.getMonth()
}

const checkYesterday = (date: number) => {
  const inputDate = new Date(date).getDate()
  const today = new Date().getDate()
  const yesterday = today - 1
  const diffDays = inputDate - yesterday
  return diffDays == 1
}

enum ChatFactoryTypes {
  NEW_CHAT = 'NEW_CHAT',
  LISTING_CHATS = 'LISTING_CHATS',
  UNREAD_ROOMS = 'UNREAD_ROOMS',
}

enum ListingWidgetTypes {
  STATUS = 'STATUS',
  PQS = 'PQS',
  VIEWS = 'VIEWS',
  INFO = 'INFO',
  APPLICANTS = 'APPLICANTS',
}

const getRecentlyViewed = () => {
  const countryCode = Cookies.get(MEMBER_COUNTRY_COOKIE)
  const recentViewedString = decodeURIComponent(
    Cookies.get(`${countryCode}_${VIEWED_LISTING_COOKIE}`) ||
      Cookies.get(`_${VIEWED_LISTING_COOKIE}`) ||
      ''
  )
  let viewedAdsList: string[] = recentViewedString.split(',').filter(Boolean)
  return viewedAdsList
}

const getWidgetByType = (widgets: DynamicObject, type: string) => {
  const data = widgets.filter((widget: DynamicObject) => widget.type == type)
  if (data[0]) {
    return data[0]
  }
  return null
}

type style = {
  underline: boolean
  bold: boolean
}

const generateStyleClasses = (style: style) => {
  if (!style) return ''
  return `${style?.underline ? ` text-with-underline` : ''}${style?.bold ? ' fw-bold' : ''}`
}

type CSSVariables = {
  '--underline-color': string
}

const styleHelper = (style: React.CSSProperties, CSSVariables?: CSSVariables) => {
  const styles = {
    '--underline-color': CSSVariables
      ? CSSVariables['--underline-color']
      : style?.color
      ? style?.color
      : 'black',
  } as React.CSSProperties
  return {...styles, ...style}
}

const customUnitsSelectStyles = (unitsOptionsLength) => {
  return {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      cursor: unitsOptionsLength < 2 ? 'not-allowed' : 'pointer',
      '&:hover': {
        border: 'none',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: unitsOptionsLength > 1 ? 'black' : 'grey',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      color: unitsOptionsLength > 1 ? 'black' : 'grey',
    }),
    menu: (provided: any) => ({
      ...provided,
    }),
    option: (provided: any) => ({
      ...provided,
    }),
  }
}

export {
  ChatFactoryTypes,
  JOB_POSTER_TYPE,
  JOB_SEEKER_TYPE,
  ListingWidgetTypes,
  checkIfMonth,
  checkIfToday,
  checkIfWeek,
  checkYesterday,
  formatNumber,
  getRecentlyViewed,
  getWidgetByType,
  generateStyleClasses,
  styleHelper,
  customUnitsSelectStyles,
}
