import Api from './api'
import { AxiosResponse} from "axios";
import DynamicObject from "../models/dynamic-object";

export default (url : string): Promise<AxiosResponse> => {
    let newUrl = url.replace(/,/g,",")
    const path = 'seeker/v1/applied-jobs'
    const fullUrl = newUrl.replace('http:',"https:")
    const apiUrl : string = (url == '') ?   `${path}?expand=jobApplicantCount,bookmarkStatus,first_image_uri,city_name,subcategory_name,neighborhood_name,category_name,custom_param_array,services,card,bookmarkStatus,category_reporting_name,subcategory_reporting_name,city,neighborhood&page=1&per-page=30&fields=neighborhood.name_en,city.name_english` : fullUrl
    return Api.getInstance(false,false,process.env.REACT_APP_OPENSOOQ_API_URL_VERTICAL).request.get(apiUrl, path)
}