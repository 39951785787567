import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Swal from "sweetalert2";
import addToFavoriteApi from "../../../api/listing/addToFavorite.api";
import getListingApi from "../../../api/listing/get.listing.api";
import getListingApplicantApi from "../../../api/listing/getListingApplicant.api";
import unFavoriteAdApi from "../../../api/listing/unFavoriteAd.api";
import { useLang } from "../../../app/i18n/OpenSooqi18n";
import { logScreenLoad } from "../../../logging/helpers/commonLoggingFunctions";
import { hexToRGB } from "../../assets/ts/_utils";
import { showError, showSuccess } from "../../helper/SystemAlert";
import { cvLabelAligner, getApplicantImage, isDeviceMobile } from "../../helpers/commonFunctions";
import { ApplicantInterface, ApplicantStatus } from "../../interfaces/ApplicantInterface";
import { MyListingViewInterface } from "../../interfaces/MyListingViewInterface";
import { ListingInfo } from "../common/ListingInfo";


const CvStatus = (status: ApplicantStatus)=>{
   
    let backgroundRGB = hexToRGB(status.color);
    const noticeColor = `rgba(${backgroundRGB.join(',')}, 0.05)`;
    const borderColor = status.color;
    const bulletColor = status.color;
    return (
        <div style={{backgroundColor: noticeColor, border: `1px dashed ${borderColor}`}} className={`'align-self-center justify-self-end notice d-flex flex-row rounded-4 my-1 px-5 py-5 w-100 h-30`}>
                <div className='d-flex flex-stack flex-grow-1 justify-content-start'>
                    <div className='fw-semibold align-self-start'>
                    <div className="d-flex align-items-start">
                        <span className={`bullet bullet-vertica..old,l h-45px w-4px`} style={{color: bulletColor, backgroundColor: bulletColor}}></span>
                        <div className="d-flex justify-content-start flex-column ms-3">
                            <div className="text-dark fw-bold text-hover-primary fs-4">
                                <span className="text-start text-dark fw-normal">{status.title}</span>
                                <div className="text-start text-dark fw-bold">
                                    {status.subTitle}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>           
    )
}

const ApplicantsLoader = ()=>{
    const SkeletonCards = Array.from({length:20}, (_, index) => {
        return (
            <div className={`${isDeviceMobile() ? 'w-100' : 'w-80'} col-lg-4 ps-4 pe-4 text-center col-4 col-md-4 radius-8 d-flex flex-column`} key={index}>
                <div className=" align-self-start fs-3 text-gray-400"><Skeleton width={'50%'}/></div>
                <div className="card h-100 shadow-lg">
                    <h2 className="align-self-start text-gray-600 fw-normal mx-5 my-5"></h2>
                    <div className="card-body d-flex flex-center flex-column py-5 px-5 justify-content-between">
                        <div className="w-100 d-flex flex-row justify-content-start px-5 mb-5">
                            <div className="symbol symbol-32px mb-5 me-5">
                                <Skeleton style={{maxWidth : "50px",objectFit: "cover" ,borderRadius : "10px",padding:"0",height: "50px",width:"50px"}} />
                            </div>
                            <div className="d-flex flex-column justify-content-start align-items-start">
                                <a href="#" className="fs-1 text-gray-800 text-hover-primary fw-bold mb-1"><Skeleton className="w-100px"/></a>
                                <div className="fs-6 fw-semibold text-gray-800 mb-2 text-center">
                                    <Skeleton className="w-100px"/>  
                                </div>
                                <div className="fs-4 text-gray-400 fw-bold"><Skeleton className="w-50px"/></div>
                            </div>
                        </div>
                        <div className=" w-100 d-flex flex-row flex-center flex-wrap mb-5">
                            <div className="w-100 mx-15 p-5">
                                <div className="fs-6 fw-bold text-gray-700 flex flex-wrap h-100px display-inline" style={{overflow: 'hidden'}}>
                                    <Skeleton className="w-100px"/>
                                </div>
                                <div className="fs-6 fw-bold text-gray-700 flex flex-wrap h-50px">
                                    <Skeleton className="w-100px"/>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })
    return <>
            {SkeletonCards}
        </>
}


const  Applicants = () => { 
    const listingId = parseInt(new URLSearchParams(window.location.search).get('id') ?? '0');
    const [loader,setLoader] = useState<boolean>(true);
    const [listing,setListing] = useState<MyListingViewInterface>()
    const [searchData,setSearchData] = useState<ApplicantInterface[]>([])
    const [sortOptions, setSortOptions] = useState<{label : string, value : string, isDisabled: boolean}[]>([]);
    const [cvStatuses, setCvStatuses] = useState<{label:string, key: string}[]>([]);
    const [selectedSortOption, setSelectedSortOption] = useState<{label: string, value:string, isDisabled: boolean}>(undefined);
    const [selectedCvStatus, setSelectedCvStatus] = useState<{label: string, key: string}>(undefined);
    const [totalApplicants, setTotalApplicants] = useState(0);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState<number>(undefined);
    const lang = useLang();
    const Intl = useIntl();
    const navigate = useNavigate();

    const fetchApplicants = useCallback(()=>{
        setLoader(true);
        getListingApplicantApi(listingId, page, selectedSortOption ? selectedSortOption.value : undefined, selectedCvStatus ? selectedCvStatus.key : undefined).then((response) => {
            if(response && response.data && response.data.result)
            {   
                const result = response.data.result;
                if(result.data &&  result.data.items)
                {   
                    setSearchData((old) => [...old, ...result.data.items])
                }
                if(result.data._meta)
                {
                    setTotalApplicants(result.data._meta.totalCount)
                    setLastPage(result.data._meta.pageCount);
                }

                const filters = response.data.result.data.extraData?.filters;
                const sort = response.data.result.data.extraData?.sort;

                if(Array.isArray(sort))
                {   
                    setSortOptions(sort.map((sortOption: {label:string, key: string}) => {
                        return {
                            label: sortOption.label,
                            value: sortOption.key,
                            isDisabled: false
                        }
                    }))
                }

                if(Array.isArray(filters))
                {
                    setCvStatuses(filters)
                    if(selectedCvStatus === undefined && filters.length > 0)
                        setSelectedCvStatus(filters[0])             
                }

                setPage((old)=>old+1);
                setLoader(false);
            }
        })
    }, [listingId, page, selectedSortOption, selectedCvStatus])
    
    useEffect(() => {
        getListingApi(listingId).then((response) => {
            if(response && response.data)
            {
                setListing(response.data);
            }
        })
    },[listingId])


    useEffect(()=>{
        if(!lastPage && lastPage !== 0)
            fetchApplicants();
    }, [lastPage])


    const paginate = () => {
        if(page > 1)
            fetchApplicants();
    };

    const handleCardClick = (id: number) => {        
        navigate(`/listings/my-listings/ApplicantCVFullView/${id}`);
    }

    const reset = ()=>{
        setSearchData([]);
        setLastPage(undefined);
        setPage(1);
    }

    const onSortOptionChange = (e : {label: string, value: string, isDisabled: boolean})=>{
        if(selectedSortOption && selectedSortOption.value === e.value)
            return;
        setSelectedSortOption(e);
        reset();
    }

    const onCvStatusChanged = (key : string)=>{
        if(selectedCvStatus.key === key)
            return;
        setSelectedCvStatus(cvStatuses.find((cvStatus) => cvStatus.key === key));
        reset();
    }

    useEffect(()=>{
        logScreenLoad()
    }, [])


  const favoriteAd = (postId : number, index: number) => {        
    addToFavoriteApi(postId,{}).then((response : any) => {
      if(response.status == 200 || response.status == 201){
        setSearchData((old) => {
            old[index] = {
                ...old[index],
                card: { ...old[index].card, 
                    bookmarkStatus: true
                }
            }
            return [...old];
        })
        showSuccess(Intl,lang, Intl.formatMessage({id : "myListingView.AddToFavorite.Description"}), Intl.formatMessage({id : "myListingView.AddToFavorite"}));
      }
    }).catch(() => {
      showError(Intl,lang);
    });
  };

  const unFavorite = (postId : number, index: number) => {
    unFavoriteAdApi(postId,{}).then((response : any) => {
      if(response.status == 202){
        setSearchData((old) => {
            setSearchData((old) => {
                old[index] = {
                    ...old[index],
                    card: { ...old[index].card, 
                        bookmarkStatus: false
                    }
                }
                return [...old];
            })
            return [...old];
        })
        showSuccess(Intl,lang, " ", Intl.formatMessage({id : "myListingView.RemoveFromFavorite"}));
      }else{
        showError(Intl,lang);
      }
    }).catch(() => {
      showError(Intl,lang);
    });
  };

  const showModal = (applicant: ApplicantInterface, index: number) => {
    const postId : number = applicant.card.post_id
    const bookmarked : boolean = applicant.card.bookmarkStatus
    const content = (!bookmarked) ? Intl.formatMessage({id : 'posts.favorite.add'}) : Intl.formatMessage({id : 'posts.favorite.remove'})
    if(!bookmarked){
      favoriteAd(postId, index)
      return;
    }
    Swal.fire({
      icon: "warning",
      html:
      `<div class="pe-10 ps-10 fw-bolder">${content}</div`,
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText : Intl.formatMessage({id : 'no'}),
      confirmButtonText: Intl.formatMessage({id : 'yes'}),
      customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light-danger"
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if(result.isConfirmed){
        unFavorite(postId, index)
      }
    })
  };


    const FavoriteButton = (applicant : ApplicantInterface, index: number) =>{
            return (
                <div className="fs-6" onClick={()=>showModal(applicant, index)}>
                    <button disabled={false} className={'btn shadow'} onClick={(e) => {
                        e.stopPropagation()
                        showModal(applicant, index);
                    }}>
                        {(applicant.card.bookmarkStatus) ?
                            <svg data-id={applicant.applicant_id} viewBox="0 0 32 32" width="25" height="25"
                                    data-name="iconFavouriteFill" className="bigContainer" >
                                <path fill="#ee5555" stroke="#ee5555" strokeWidth="3"
                                        d="M16 28.467l-1.933-1.76c-6.867-6.227-11.4-10.333-11.4-15.373 0-4.107 3.227-7.333 7.333-7.333 2.32 0 4.547 1.080 6 2.787 1.453-1.707 3.68-2.787 6-2.787 4.107 0 7.333 3.227 7.333 7.333 0 5.040-4.533 9.147-11.4 15.387l-1.633 1.747z"></path>
                            </svg> : <svg viewBox="0 0 32 32" width="25" height="25" data-name="iconFavouriteFill" className="bigContainer">
                                <path fill="#fff" stroke="#ee5555" stroke-width="3"
                                        d="M16 28.467l-1.933-1.76c-6.867-6.227-11.4-10.333-11.4-15.373 0-4.107 3.227-7.333 7.333-7.333 2.32 0 4.547 1.080 6 2.787 1.453-1.707 3.68-2.787 6-2.787 4.107 0 7.333 3.227 7.333 7.333 0 5.040-4.533 9.147-11.4 15.387l-1.633 1.747z"></path>
                            </svg>}
                    </button>
                </div>
            )
        }

    const List:React.FC<{searchData:ApplicantInterface[]}> = ({searchData}) => {
        return <React.Fragment key={'favoriteListings'}>
            {searchData.map((applicant  : ApplicantInterface,index: number) => {
            if(!applicant)
                return <></>
            return (
            <>
            <div className={`${isDeviceMobile() ? 'w-100' : 'w-80'} col-lg-4 mb-5 ps-4 pe-4 text-center col-4 radius-8 d-flex flex-column`} key={applicant.applicant_id} >
                    <div className="align-self-start fs-4 text-gray-400">{applicant.card.apply_date}</div>
                    <div role="button" className="card h-100 shadow-sm" onClick={()=>handleCardClick(applicant.applicant_id)}>
                        <div className="d-flex flex-row justify-content-between m-5 align-items-center">
                            <h2 className="align-self-center fs-3 text-gray-600 fw-normal">{applicant && applicant.card && applicant.card.sector}</h2>
                            {FavoriteButton(applicant, index)}
                        </div>
                        <div className={`card-body w-100 d-flex flex-center flex-column py-4 px-5 ${ applicant && applicant.card && applicant.card.applicant_status ? 'justify-content-between' : 'justify-content-start'}`}>
                            <div className="w-100 d-flex flex-row justify-content-start px-1 mb-5">
                                <div className="symbol symbol-32px mb-5 me-3">
                                    <img src={getApplicantImage(applicant.card?.avatar)} alt="image"/>
                                </div>
                                <div className="d-flex justify-content-start align-items-start flex-column " style={{overflowWrap : "anywhere"}}>
                                    <span className={`text-gray-800 fs-2 mb-1 fw-bold text-start customPostTitleText h-60px`}>{applicant.card?.name}</span>
                                    
                                    <div className="fs-6 fw-semibold text-gray-800 mb-2 text-start">
                                        {applicant.card?.location}
                                    </div>
                                    <div className="fs-6 text-gray-400 text-wrap w-100 text-start">{applicant.card?.currentWork}</div>
                                </div>
                            </div>
                            <div className="w-100 d-flex flex-row flex-center flex-wrap px-1 mb-5">
                                <div className="w-100 mx-16">
                                    <div className="fs-4 fw-bold text-gray-700 flex flex-wrap h-70px w-100 display-inline gap-2 mx-1" style={{overflow: 'hidden'}}>
                                        {applicant.card?.tags?.map((item,index) => {
                                            return  <span key={index} className="badge bg-secondary text-black fw-normal fs-5 pt-2 h-30px">{item}</span>
                                        })}
                                    </div>
                                    <div className="fs-3 fw-bold text-gray-700 flex flex-wrap h-40px px-1">
                                        {applicant && applicant.card && applicant.card.cv_match && <span key="cv-match" style={{backgroundColor: applicant.card.cv_match.color}} className="fs-5 badge p-3 me-5 my-2">{cvLabelAligner(applicant.card.cv_match.label)}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 d-flex flex-row flex-center flex-wrap">
                                {applicant.card && applicant.card.applicant_status && <CvStatus type={applicant.card.applicant_status.type} color={applicant.card.applicant_status.color} title={applicant.card.applicant_status.title} subTitle={applicant.card.applicant_status.subTitle} />}
                                {applicant.card && !applicant.card.applicant_status && !isDeviceMobile() && <div className="h-100px col-lg-4 ps-4 pe-4 text-center col-4 col-md-4 radius-8 d-flex flex-column"></div>}
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )
        })}
        {loader && <ApplicantsLoader />}
        </React.Fragment>  
    };

    const NavTabs = ()=>{
        return (
            <div className={`nav nav-tabs nav-line-tabs nav-line-tabs-2x d-flex flex-row ${isDeviceMobile() ? 'justify-content-center' : 'justify-content-between'}`}>
                <ul className="fw-bolder mb-5 fs-3 w-70 px-10 pt-5 pb-5 d-inline-block" style={{overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap'}}>
                    {
                        cvStatuses.map((_cvStatus) => {
                            return (
                                <li className={`nav-item d-inline-block`} key={_cvStatus.key} onClick={()=>onCvStatusChanged(_cvStatus.key)}>
                                    <a className={`nav-link fw-normal ${_cvStatus.key === selectedCvStatus?.key && 'active fw-bold'}`} data-bs-toggle="tab" href="#All" >
                                        {_cvStatus.label}
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
                <Select
                    value={selectedSortOption}
                    placeholder={Intl.formatMessage({id : 'applicants.sort'})}
                    aria-label={Intl.formatMessage({id : 'applicants.sort'})}
                    className={`select2-hidden-accessible d-inline-block ${isDeviceMobile() ? 'w-100 px-5' : 'w-300px px-10'}  py-5`}
                    onChange={onSortOptionChange}
                    closeMenuOnSelect={true}
                    options= {sortOptions} 
                    isSearchable={true}
                    styles={{
                        control: (baseStyles, state) => ({...baseStyles})
                    }}
                />
            </div>
        )
    }

    return (
        <>
            <ListingInfo applicantsView={true}  refresh={()=>{}} listing={listing}/>
            <div className="card mt-5">
                <div>
                    <NavTabs />
                </div>
                <div className="card-body">
                    {<div className="fs-1 fw-bold mb-12">{`${Intl.formatMessage({id: 'applicants'})} (${totalApplicants})`}</div>}
                    {searchData && 
                    <InfiniteScroll dataLength={searchData.length} endMessage={<></>} style={{overflowX: 'hidden'}} loader={<></>} next={paginate} hasMore={ (lastPage === undefined) || (page <= lastPage) }> 
                        <div className="row g-2 mx-1" style={{overflowX: 'hidden'}}>
                            <List searchData={searchData}/>
                        </div>
                    </InfiniteScroll>}
                </div>
            </div>
        </>
    )
}


export { Applicants };
