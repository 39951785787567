import {useMemo, useState} from 'react'
import NewMyListingsDesktop from './NewMyListingsDesktop'
import {useIntl} from 'react-intl'
import DesktopListSkeleton from './skeletons/DesktopListSkeleton'
import {MemberShipModal} from '../../../payment/MemberShipModal'
import {EnumUxTypes, Post} from '../../../../../logging/types/LoggingTypes'
import {DeactivateListModal} from '../../../../layout/components/modals/DeactivateListModal'
import getDeleteReasons from '../../../../../api/listing/getDeleteReasons'
import DynamicObject from '../../../../../models/dynamic-object'
import Swal from 'sweetalert2'
import {PointOfSales} from '../../../../helper/PointOfSales'
import deleteForeverApi from '../../../../../api/listing/deleteForever.api'
import {showError, showSuccess} from '../../../../helper/SystemAlert'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import {ListingSharePopup} from './ListingSharePopup'
import AddNewListing from '../../../common/AddNewListing'
import NewMyListingsMobile from './NewMyListingsMobile'
import {
  extractUtms,
  footerLinksMapper,
  generateOpenSooqRedirectUrl,
  getAddPostIntegrationUrl,
  getCookies,
  isDeviceMobile,
  redirectionInterceptor,
  setBackURLForAddPost,
} from '../../../../helpers/commonFunctions'
import MobileListingShowMore from './MobileListingShowMore'
import {POST_ACTIONS, PostActionInput} from './lisitingtypes'
import {useNavigate} from 'react-router-dom'
import MobileListSkeleton from './skeletons/MobileListSkeleton'
import MyListingsPagination from './MyListingsPagination'
import { logMyListingsActiveListingClick, logMyListingsCopyListingClick, logMyListingsDeleteListingClick, logMyListingsEditListingClick, logMyListingsIncreaseLeadsListingClick, logMyListingsListingChatClick, logMyListingsShareListingClick, logMyListingsStatsListingClick } from '../../../../../logging/helpers/commonLoggingFunctions'

export default function MyListingsContainer({
  listings,
  isListingLoading,
  pagesCount,
  setSelectedPage,
  handlePageChange,
  currentPage,
  handleDeleteLocalListing,
}) {
  const Intl = useIntl()
  const lang = useLang()
  const navigate = useNavigate()
  const [showBundle, setShowBundle] = useState(false)
  const [pickedListingId, setPickedListingId] = useState<number>(0)
  const [selectedListing, setSelectedListing] = useState<any>(null)
  const [type, setType] = useState<string[]>([])
  const [cta, setCta] = useState('')
  const [pos, setPos] = useState('')
  const [reasons, setReasons] = useState<DynamicObject>({})
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const [showCustomModal, setShowCustomModal] = useState<boolean>(false)
  const [showMoreOptionsMobile, setShowMoreOptionsMobile] = useState<boolean>(false)
  const [moreOptionsMobile, setMoreOptionsMobile] = useState<any[]>([])
  const country = getCookies().ecountry
  const memberId = useMemo(() => getCookies().userInfo.id ?? 0, [])
  const openSooqUrl = (process.env.REACT_APP_OPENSOOQ_BASE ?? '')
    .replace('{country}', country ?? '')
    .replace('{lang}', lang ?? '')

  const showBundleModalHandler = () => {
    setShowBundle(true)
  }

  const deleteListingFromSearchData = async (id) => {
    // filter data based on listing id
    // await fetchListings()
    handleDeleteLocalListing(id)
  }
  const redirectToEditPost = (listing, type: string) => {
    if (!listing.quality.isEnabled && type === POST_ACTIONS.EDIT) return
    setBackURLForAddPost()
    window.location.href = window.location.href = getAddPostIntegrationUrl(
      getCookies().NEXT_LOCALE,
      type,
      listing.listingId ?? 0,
      0,
      false
    )
  }
  const redirectToStats = (listing) => {
    navigate(`/listings/my-listings/listing-stats?id=${listing.listingId}`)
  }

  const handlePostAction = ({action, isActive = true, listing, options}: PostActionInput) => {
    if (!isActive) return
    setPickedListingId(listing.listingId)

    const redirectUrl = `${process.env.REACT_APP_ADD_POST_REDIRECT_URL}?v=2&source=${
      isDeviceMobile() ? 'mobile' : 'desktop'
    }&lang=${lang}&flow=activate&post_id=${listing?.listingId}&from=my-opensooq&return=${
      window.location.href
    }&${extractUtms()}`


    switch (action) {
      case POST_ACTIONS.ACTIVE:
        logMyListingsActiveListingClick(listing.loggingData ,'wallet_cell', EnumUxTypes.BUTTON)
        setBackURLForAddPost()
        window.location.href = redirectUrl
        break
      case POST_ACTIONS.EDIT:
        logMyListingsEditListingClick(listing.loggingData, 'listing_cell', EnumUxTypes.BUTTON)
        redirectToEditPost(listing, 'edit')
        break
      case POST_ACTIONS.COPY:
        logMyListingsCopyListingClick(listing.loggingData, 'listing_cell', EnumUxTypes.BUTTON)
        redirectToEditPost(listing, 'republish')
        break
      case POST_ACTIONS.DELETE:
        logMyListingsDeleteListingClick(listing.loggingData, 'wallet_cell', EnumUxTypes.BUTTON)
        const isPromoted =
          listing.features?.isPromoted ||
          listing.features?.isBumpup ||
          listing.features?.isVip ||
          false
        deleteListing(listing.listingId, isPromoted)
        break
      case POST_ACTIONS.DELETE_FOREVER:
        logMyListingsDeleteListingClick(listing.loggingData, 'wallet_cell', EnumUxTypes.BUTTON)
        deleteForeverHandler(listing.listingId)
        break
      case POST_ACTIONS.CHAT:
        logMyListingsListingChatClick(listing.loggingData, 'listing_cell', EnumUxTypes.BUTTON)
        window.open(`/listings/chats?id=${listing.listingId}`, '_blank')
        break
      case POST_ACTIONS.TERMS_OF_USE:
        redirectionInterceptor()
        const termsLink = `${openSooqUrl}/${footerLinksMapper['term-of-use'][lang]}`
        window.open(termsLink, '_blank')
        break
      case POST_ACTIONS.SHARE:
        logMyListingsShareListingClick(listing.loggingData, 'listing_cell', EnumUxTypes.BUTTON)
        setSelectedListing(listing)
        setShowCustomModal(true)
        break
      case POST_ACTIONS.MORE:
        if (isDeviceMobile()) {
          setSelectedListing(listing)
          setMoreOptionsMobile(options)
          setShowMoreOptionsMobile(true)
        } else {
        }
        break
      case POST_ACTIONS.CARFAX:
      case POST_ACTIONS.CARSEER:
      case POST_ACTIONS.MOJAZ:
        navigate(`/services/car-reports`)
        break
      case POST_ACTIONS.STATS:
        logMyListingsStatsListingClick(listing.loggingData, 'wallet_cell', EnumUxTypes.BUTTON)
        redirectToStats(listing)
        break
      case POST_ACTIONS.VIEW_ON_OPENSOOQ:
        redirectionInterceptor()
        let link =
          generateOpenSooqRedirectUrl(
            process.env.REACT_APP_OPENSOOQ_BASE ?? '',
            getCookies().ecountry ?? '',
            lang
          ) +
          '/search/' +
          listing?.listingId +
          '/' +
          encodeURIComponent(listing?.title?.text?.replace(/\s+/g, '-'))
        window.open(link, '_blank')
        break
      case POST_ACTIONS.PROMOTE_VIP:
        logMyListingsIncreaseLeadsListingClick(listing.loggingData, 'wallet_cell', EnumUxTypes.LINK)
        console.log('promote_vip')
        break
        case POST_ACTIONS.PROMOTE:
        setPos(PointOfSales.MY_LISTINGS_POS_PROMOTE_LISTING)
        logMyListingsIncreaseLeadsListingClick(listing.loggingData, 'wallet_cell', EnumUxTypes.LINK)
        setType(['Turbo', 'Premium', 'Bumpup'])
        setCta('premium')
        showBundleModalHandler()
        break
        case POST_ACTIONS.REPOST:
        setPos(PointOfSales.MY_LISTINGS_POS_REPOST_LISTING)
        logMyListingsIncreaseLeadsListingClick(listing.loggingData, 'wallet_cell', EnumUxTypes.LINK)
        setType(['Turbo', 'Premium', 'Bumpup'])
        setCta('bumpup')
        showBundleModalHandler()
        break
      case POST_ACTIONS.APPLICANTS:
        navigate(`/listings/my-listings/job-applicants?id=${listing.listingId}`)
        break
      default:
        break
    }
  }

  const deleteListing = (listingId: number, showConfirmation: boolean) => {
    getDeleteReasons(listingId).then((response) => {
      setReasons(response.data)
      if (showConfirmation) {
        const text = Intl.formatMessage({id: 'deleteListingPopup'})
        Swal.fire({
          icon: 'warning',
          html: `<div class="pe-10 ps-10 fw-bolder">${text}</div>`,
          buttonsStyling: false,
          showCancelButton: true,
          cancelButtonText: Intl.formatMessage({id: 'no'}),
          showLoaderOnConfirm: true,
          confirmButtonText: Intl.formatMessage({id: 'yes'}),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-light-danger',
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            setShowDeactivateModal(true)
          }
        })
      } else setShowDeactivateModal(true)
    })
  }

  const deleteForeverHandler = (listingId: number) => {
    const text = Intl.formatMessage({id: 'deleteForever'})
    Swal.fire({
      icon: 'warning',
      html: `<div class="pe-10 ps-10 fw-bolder">${text}</div>`,
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText: Intl.formatMessage({id: 'no'}),
      showLoaderOnConfirm: true,
      confirmButtonText: Intl.formatMessage({id: 'yes'}),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light-danger',
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteForeverApi(listingId)
          .then((response) => {
            showSuccess(Intl, lang)
              deleteListingFromSearchData(listingId)
          })
          .catch((error) => {
            showError(Intl, lang)
          })
      }
    })
  }

  const handleCloseShareModal = () => {
    setShowCustomModal(false)
    setSelectedListing(null)
  }

  return (
    <>
      {!isDeviceMobile() && (isListingLoading || listings.length > 0) && (
        <div className='border border-2  top-border-radius card'>
          <div className='sticky-mylisting-header'>
            <div
              className='row fw-bold mb-5 py-3 mx-0 '
              style={{
                backgroundColor: '#F4F5F7',
                borderBottom: '1px solid #E5E5E5',
              }}
            >
              <div className='col col-2-8'>
                {Intl.formatMessage({id: 'mylistings.container.list'})}
              </div>
              <div className='col col-1'>
                {Intl.formatMessage({id: 'mylistings.container.category'})}
              </div>
              <div className='col col-1'>
                {Intl.formatMessage({id: 'mylistings.container.location'})}
              </div>
              <div className='col col-1'>
                {Intl.formatMessage({id: 'mylistings.container.created'})}
              </div>
              <div className='col col-1'>
                {Intl.formatMessage({id: 'mylistings.container.expires'})}
              </div>
              <div className='col col-0-7'>
                {Intl.formatMessage({id: 'mylistings.container.quality'})}
              </div>
              <div className='row col col-1-6'>
                <div className='col col-6'>
                  {Intl.formatMessage({id: 'mylistings.container.views'})}
                </div>
                <div className='col col-6'>
                  {Intl.formatMessage({id: 'mylistings.container.leads'})}
                </div>
              </div>
              <div className='col col-1-1'>
                {Intl.formatMessage({id: 'mylistings.container.increaseLeads'})}
              </div>
              <div className='col col-1-25 ms-5'>
                {Intl.formatMessage({id: 'mylistings.container.actions'})}
              </div>
            </div>
          </div>

          {!isListingLoading && listings.length > 0 && (
            <div className='ms-3 me-1'>
              {listings.map((val, idx) => {
                return (
                  <div key={idx}>
                    {idx != 0 && <div className='dotted-line py-1 my-2' />}
                    <NewMyListingsDesktop handlePostAction={handlePostAction} data={val} />
                  </div>
                )
              })}
            </div>
          )}
          {isListingLoading &&
            Array(5)
              .fill(1)
              .map((val, idx) => {
                return (
                  <div key={idx} className='ms-2'>
                    {idx != 0 && <div className='dotted-line py-1 my-2' />}
                    <DesktopListSkeleton />
                  </div>
                )
              })}
        </div>
      )}

      {isDeviceMobile() && (isListingLoading || listings.length > 0) && (
        <div>
          <div className='d-flex flex-column gap-5 align-items-center'>
            {!isListingLoading &&
              listings.length > 0 &&
              listings.map((val, idx) => {
                return (
                  <NewMyListingsMobile
                    handlePostAction={handlePostAction}
                    data={val}
                    key={idx}
                  />
                )
              })}

            {isListingLoading &&
              Array(5)
                .fill(1)
                .map((val, idx) => {
                  return <MobileListSkeleton key={idx} />
                })}
          </div>
        </div>
      )}

      {!isListingLoading && listings.length == 0 && (
        <div className='my-15'>
          <h1 className='mb-8'>{Intl.formatMessage({id: 'ads.noListings'})}</h1>
          <AddNewListing
            isContained={false}
            source={'default'}
            withBorder={true}
            widgetName='add_listing_card'
          />
        </div>
      )}

      <MemberShipModal
        show={showBundle}
        type={type}
        handleClose={() => {
          setShowBundle(false)
        }}
        id={pickedListingId}
        pos={pos}
        cta={cta}
        uxType={EnumUxTypes.LIST_CELL}
        triggerLogging={false}
      />
      <DeactivateListModal
        reasons={reasons}
        show={showDeactivateModal}
        id={pickedListingId}
        type='edit'
        deleteListingFromSearchData={deleteListingFromSearchData}
        handleClose={() => {
          setShowDeactivateModal(false)
        }}
        pos={PointOfSales.MY_LISTINGS_POS_DELETE_WIDGET_PROMOTE_LISTING}
      />

      {selectedListing && (
        <ListingSharePopup
          show={showCustomModal}
          handleClose={handleCloseShareModal}
          selectedListing={selectedListing}
        />
      )}
      <MyListingsPagination
        currentPage={currentPage}
        pagesCount={pagesCount}
        handlePageChange={handlePageChange}
      />
      {showMoreOptionsMobile && selectedListing && (
        <MobileListingShowMore
          show={showMoreOptionsMobile}
          setShowMoreOptionsMobile={setShowMoreOptionsMobile}
          options={moreOptionsMobile}
          setMoreOptionsMobile={setMoreOptionsMobile}
          selectedListing={selectedListing}
          handlePostAction={handlePostAction}
        />
      )}
    </>
  )
}
