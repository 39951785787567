import { ILoggingAPIBody, ILoggingAPIsuccessResposnse } from "../../logging/types/LoggingTypes";
import Api from "../api";

export default(
    body: ILoggingAPIBody[],
): Promise<ILoggingAPIsuccessResposnse> => {
    const url = `${process.env.REACT_APP_NEXT_PUBLIC_LOGGING_URL_CLIENT || "https://sooqdev3.com/api/logging/v1"}`;
    return Api.getInstance().request.post(url, body, url);
};

