import { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import getAdLeadsApi from "../../api/listing/getAdLeads.api";
import { Button } from "../../app/layout/components/opensooq/Button";
import { SearchComponent } from "../../app/layout/components/opensooq/SearchComponent";
import Moment from 'moment';
import { TableLoader } from "../../app/layout/components/opensooq/TableLoader";
import { MemberInfoInterface } from "../interfaces/MemberInfoInterface";
import { REGULAR_MEMBER_TYPE, SHOP_MEMBER_TYPE } from "../helper/MemberTypes";
import { toAbsoluteUrl } from "../helpers";
import { useLang } from "../i18n/OpenSooqi18n";
import { MemberShipModal } from "../modules/payment/MemberShipModal";
import Skeleton from "react-loading-skeleton";
import getAdStatsApi from "../../api/getAdStats.api";
import DynamicObject from "../../models/dynamic-object";
import { logScreenLoad } from "../../logging/helpers/commonLoggingFunctions";
import { generateOpenSooqRedirectUrl, getCookies, getProfileFunction, isDeviceMobile, redirectionInterceptor } from "../helpers/commonFunctions";
import { useParams, useSearchParams } from "react-router-dom";
import { AuthContext } from "../i18n/AuthProvider";


export interface MemberLeadInterface{
    date                :   number
    member              :   MemberInterface 
    date_timestamp      :   number   
}

export interface MemberInterface{
    date                :   number
    profile_picture     :   string
    record_insert_date  :   string
    full_name           :   string
    phone_number        :   string
    hide_contact_info   :   number
    id : number 
}

const LeadManagement = () => {
    const adId : number | string = new URLSearchParams(window.location.search).get('id') ?? '0';
    // const type =  new URLSearchParams(window.location.search).get('type')?.toLowerCase() ?? 'views';
    const [searchParams, setSearchParams] = useSearchParams();
    const type = searchParams.get('type') ?? 'views';
    const [searchData,setSearchData] = useState <MemberLeadInterface[]>([]); 
    const [membersList,setMembersList] = useState <MemberLeadInterface[]>([]); 
    const [member,setMember] = useState <MemberInfoInterface>(); 
    const [searchString, setSearchString] = useState("")
    const [loader,setLoader] = useState <boolean>(true)
    const [enableLeadsReport, setEnableLeadsReport] = useState(false);
    const [leadsReportFlowType, setLeadsReportFlowType] = useState<'UpSelling' | 'Membership' | 'Sales' | undefined>(undefined);
    const [showBundle,setShowBundle] = useState (false);
    const Intl = useIntl();   
    const lang = useLang();
    const [counters,setCounters] = useState <DynamicObject>();
    const cookies = getCookies()
    const {userIdentity} = useContext(AuthContext)

    useEffect(() => {
        getProfileFunction().then(response => {
            const results = response.data;
            setMember(results)
        })

        getAdStatsApi(parseInt(adId)).then(response => {
            let data = {
                "total" : {
                    "members" : response.data.bookmarks.total - response.data.bookmarks.guest,
                    "guests"  : response.data.bookmarks.guest
                }
            }
            if(type == 'views'){
                data = {
                    "total" : {
                        "members" : response.data.views.total - response.data.views.guest,
                        "guests"  : response.data.views.guest
                    }
                }
            }else if (type == 'rooms'){
                data = {
                    "total" : {
                        "members" : response.data.rooms.total - response.data.rooms.guest,
                        "guests"  : response.data.rooms.guest
                    }
                }
            }else if (type == 'calls'){
                data = {
                    "total" : {
                        "members" : response.data.calls.total - response.data.calls.guest,
                        "guests"  : response.data.calls.guest
                    }
                }
            }
            setEnableLeadsReport(type === 'msgs' ? true : response.data.enableLeadsReport ?? false);
            setLeadsReportFlowType(response.data.leadsReportFlowType ?? undefined);
            setCounters(data)
            if(!response.data.enableLeadsReport)
                setLoader(false);
        });
    },[]);

    useEffect(()=>{
        if(enableLeadsReport && type)
            getLeads(type);
    }, [enableLeadsReport])

    useEffect(()=>{
        logScreenLoad()
    }, [])

    const getLeads = (type : string) => {
        getAdLeadsApi(type,adId).then((response) => {
            setMembersList(response.data[type]);
            setSearchData(response.data[type]);
            setLoader(false);
        }).catch((e) => {
            setLoader(false);
        })

    }
    const filterLead = (e : any) => {
        if(!enableLeadsReport || !e.target.value)
            return;
        setLoader(true);
        setMembersList([])
        const newSearchParams = new URLSearchParams(searchParams);
        const newType = e.target.value;
        newSearchParams.set('type', newType);
        setSearchParams(newSearchParams)
        getLeads(e.target.value)
    }

    const showBundleModalHandler = ()=>{
        if(leadsReportFlowType === 'Sales' || leadsReportFlowType === undefined || (!userIdentity || !userIdentity.id))
        {
            const contactSalesURL = `${generateOpenSooqRedirectUrl(`${process.env.REACT_APP_OPENSOOQ_BASE}/${lang === 'ar' ? 'مندوبي-المبيعات' : 'sales-representatives'}`,cookies.ecountry,lang)}`;
            window.open(contactSalesURL, 'blank');
            return;
        }
        setShowBundle(true)
    }

    useEffect(() => {
        const newList = membersList.filter(member => member.member.full_name.toLowerCase().includes(searchString.toLowerCase()))
        setSearchData(newList);
    },[searchString]);

    const list = () => {
        const callUser = (phone : string) => {
            window.open(`tel: ${phone}`, '_self');
        }
        return searchData.map((lead: MemberLeadInterface,index) => {
            const date = Moment.unix(lead.date_timestamp).format("DD-MM-YYYY hh:mm:ss");
            const postViewUri = `${generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE,cookies.ecountry,lang)}/mid/${lead.member.id}`
            return <>{!isDeviceMobile() && <tr key={index}>
                <td>
                    <div className="d-flex align-items-center">
                        <div className="symbol symbol-50px me-3">
                            <img src={lead.member.profile_picture} className="" alt="test"/>
                        </div>
                        <div className="d-flex justify-content-start flex-column">
                            <a target="_blank" onClick={redirectionInterceptor} href={postViewUri} className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{lead.member.full_name}</a>
                        </div>
                    </div>
                </td>
                <td>
                    <span className="text-gray-800 fw-semibold d-block fs-7">{date}</span>
                </td>
                <td className="d-flex flex-wrap flex-end gap-1">
                    <button disabled={(lead.member.phone_number == '' || lead.member.hide_contact_info) ? true : false} style={{minWidth: "145px"}} className={'btn blueBtn fs-5 text-white'} onClick={()=>{callUser(lead.member.phone_number)}}><i className="fas fa-phone text-white"></i> {(!lead.member.hide_contact_info && lead.member.phone_number) ? lead.member.phone_number : '' }</button>
                    <a target="_blank" style={{minWidth:`${isDeviceMobile()?'145px':''}`}} onClick={redirectionInterceptor}  href={postViewUri} className='btn btn-sm  fs-5 whiteBtn'><i className="fas fa-eye openSooqBlueColor"></i>{Intl.formatMessage({id: 'view'})}</a>
                </td>
            </tr>}
            {isDeviceMobile() && <> 
                <div className="container border-top dashed h-1">
                    <div className="row my-3 d-flex align-items-center">
                        <div className="col-2 symbol symbol-50px me-3"><img src={lead.member.profile_picture} className="" alt="test"/></div>
                        <div className="col-5"><a target="_blank" onClick={redirectionInterceptor} href={postViewUri} className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{lead.member.full_name}</a><br /><span className="text-gray-800 fw-semibold d-block fs-7">{date}</span></div>
                        <div className="col-4 "><button disabled={(lead.member.phone_number == '' || lead.member.hide_contact_info) ? true : false} style={{minWidth: "145px"}} className={'btn blueBtn fs-5 text-white mb-2'} onClick={()=>{callUser(lead.member.phone_number)}}><i className="fas fa-phone text-white"></i> {(!lead.member.hide_contact_info && lead.member.phone_number) ? lead.member.phone_number : '' }</button>
                        <a target="_blank" style={{minWidth:`${isDeviceMobile()?'145px':''}`}} onClick={redirectionInterceptor} href={postViewUri} className='btn btn-sm  fs-5 whiteBtn'><i className="fas fa-eye openSooqBlueColor"></i>{Intl.formatMessage({id: 'view'})}</a>
                        </div>
                    </div>
                </div> 
            </>
            }
        </>
        });
    };

    const setSearch = (e: any) => {
        if(!enableLeadsReport)
            return;    
        setSearchString(e.target.value);
    };
    return (
        <>
        {<div className="row">
            <div className="col-lg-6 col-12">
                <div className="border border-dashed border-gray-300 grayHoverBg w-100 bg-white rounded my-3 p-2 me-2"  >
                    <div className="d-flex align-items-center h-30px">
                        <div className="form-check form-check-custom form-check-solid mx-5"></div>
                        <div className="flex-grow-1">
                            <a href="#" className="text-gray-800 fw-bold fs-6">{Intl.formatMessage({id : "members"})}</a>
                        </div>
                       {counters && counters.total && counters.total.members !== undefined ? <span className="badge badge-light-success fs-12 fw-bold text-dark">{counters.total.members}</span> : <Skeleton width={20} height={15}/>}
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-12">
                <div className="border border-dashed border-gray-300  grayHoverBg w-100 bg-white rounded my-3 p-2 me-2"  >
                    <div className="d-flex align-items-center h-30px">
                        <div className="form-check form-check-custom form-check-solid mx-5"></div>
                        <div className="flex-grow-1">
                            <a href="#" className="text-gray-800 fw-bold fs-6">{Intl.formatMessage({id : "guests"})}</a>
                        </div>
                       {counters && counters.total && counters.total.guests !== undefined ? <span className="badge badge-light-success fs-12 fw-bold text-dark">{counters.total.guests}</span> : <Skeleton width={20} height={15}/>}
                    </div>
                </div>
            </div>
        </div>}
        {!enableLeadsReport && !loader && isDeviceMobile() && 
            <div className="d-flex flex-column align-items-center justify-content-between border border-dashed border-gray-300 w-100 bg-white rounded my-3 p-5 me-2">
                <div className="fs-3 fw-bold">{Intl.formatMessage({id: `leadsHeader.${type}`})}</div>
                <div className="fs-4 text-center">{Intl.formatMessage({id: `leadsDesc.${type}`})}</div>
                <img src={'/media/product/leads2.png'}/>
                <button className="btn btn-lg btn-primary fs-3 fw-bold" onClick={showBundleModalHandler}>{Intl.formatMessage({id: 'leads.button'})}</button>
            </div>
        }
        <div className="row g-5">
            <div className='col-12'>
                <div className='card '>   
                    {<div className="card-header border-0">
                        <div className="card-title">
                            {!loader && <SearchComponent action={setSearch} placeholder={Intl.formatMessage({id : "leadsSearch"})}/>}
                            {loader && <Skeleton width={300} height={40}/>}
                        </div>
                        <div className="card-toolbar">
                            <div className="d-flex flex-stack flex-wrap gap-4 ms-2">
                                <div className="d-flex align-items-center fw-bold">
                                    {!loader && <div className="text-gray-400 fs-7 me-2">{Intl.formatMessage({id:'kpi'})}</div>}
                                    {!loader && <select onChange={(e) => {filterLead(e)}}  className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto select2-hidden-accessible" data-control="select2" data-hide-search="true" data-dropdown-css-className="w-150px" data-placeholder="Select an option" data-kt-table-widget-4="categories" data-select2-id="select2-data-10-8plb"aria-hidden="true" data-kt-initialized="1">
                                        <option value="views" selected={(type == 'views') ? true : false} data-select2-id="select2-data-12-opco"> {Intl.formatMessage({id:"adViews.text1"})}</option>
                                        <option value="calls" selected={(type == 'calls') ? true : false} data-select2-id="select2-data-12-opco"> {Intl.formatMessage({id:"adViews.text4"})}</option>
                                        <option value="msgs" selected={(type == 'msgs') ? true : false} data-select2-id="select2-data-12-opco"> {Intl.formatMessage({id:"adViews.text7"})}</option>
                                        <option value="bookmarks" selected={(type == 'bookmarks') ? true : false} data-select2-id="select2-data-12-opco"> {Intl.formatMessage({id:"adViews.text9"})}</option>
                                        <option value="impressions" selected={(type == 'impressions') ? true : false} data-select2-id="select2-data-12-opco"> {Intl.formatMessage({id:"adViews.text13"})}</option>
                                    </select>}
                                    {loader && <Skeleton width={150} height={30}/>}
                                </div>
                            </div>
                        </div>
                    </div>}
                    <div className="card-body">
                       {!isDeviceMobile() && <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
                            <thead>
                                <tr>
                                    <td className="ps-5">
                                        {Intl.formatMessage({id : "name"})}
                                    </td>
                                    {enableLeadsReport && <td>
                                        {Intl.formatMessage({id : "date"})}
                                    </td>}
                                    <td className="text-end pe-10">
                                        {Intl.formatMessage({id : "common.contact"})}
                                    </td>
                                </tr>
                            </thead>
                            <tbody className="">
                                {loader &&  <tr>
                                    <td colSpan={3}><Skeleton count={15} height={25} className='me-1 w-100'/></td>
                                </tr>}
                                {!enableLeadsReport && !loader && !isDeviceMobile() && <tr>
                                    <td colSpan={2}>
                                        <div className="d-flex flex-row justify-content-between">
                                            {<img src={lang === 'ar' ? '/media/product/leads_people_ar.png' : '/media/product/leads_people_en.png'}/>}
                                            <div className="d-flex flex-column align-items-center justify-content-between w-50" style={{backgroundColor: 'transparent'}}>
                                                <div className="fs-3 fw-bold">{Intl.formatMessage({id: `leadsHeader.${type}`})}</div>
                                                <div className="fs-4 w-50 text-center">{Intl.formatMessage({id: `leadsDesc.${type}`})}</div>
                                                <img src={'/media/product/leads2.png'}/>
                                                <button className="btn btn-lg btn-primary fs-3 fw-bold" onClick={showBundleModalHandler}>{Intl.formatMessage({id: 'leads.button'})}</button>
                                            </div>
                                            {<img src={'/media/product/leads_buttons.png'}/>}
                                        </div>
                                    </td>    
                                </tr>}
                                {!enableLeadsReport && !loader && isDeviceMobile() && <tr>
                                    <td colSpan={2}>
                                        <div className="d-flex flex-row justify-content-between">
                                            {<img src={lang === 'ar' ? '/media/product/leads_people_ar.png' : '/media/product/leads_people_en.png'}/>}
                                            {<img src={'/media/product/leads_buttons.png'}/>}
                                        </div>
                                    </td>    
                                </tr>}
                                {enableLeadsReport && !loader && membersList.length == 0 && <tr  className="text-center m-15 "><td colSpan={3}>{Intl.formatMessage({id : "noResults"})}</td></tr>}
                                {enableLeadsReport && !loader && membersList.length > 0 && !isDeviceMobile() && list()}
                            </tbody>
                        </table>}
                        {isDeviceMobile() && !loader && enableLeadsReport && membersList.length == 0 && <div className="text-center m-15 ">{Intl.formatMessage({id : "noResults"})}</div>}
                        {isDeviceMobile() && enableLeadsReport && !loader && membersList.length > 0 && 
                        <div className="d-flex flex-column">
                            <div className="container">
                                <div className="row">
                                    <div className="col-2 symbol symbol-50px me-3 fs-4 fw-bold">{Intl.formatMessage({id : "name"})}</div>
                                    <div className="col-5 fs-4"></div>
                                    <div className="col-4 fs-4 fw-bold">{Intl.formatMessage({id : "common.contact"})}</div>
                                </div>
                            </div>
                            {list()}
                        </div> 
                        }
                    </div> 
                </div>
            </div>
        </div>
        <MemberShipModal show={showBundle} type={[leadsReportFlowType]} handleClose={() => {setShowBundle(false)}} id={(leadsReportFlowType == 'UpSelling' ? +adId : userIdentity?.id)} pos={'stats'} widgetName="list_header" actionSource="leadsReport"/>
        </>
    )}

    export {LeadManagement}
    