import Cookies from "js-cookie";
import {ContactUsValue, contactUs} from "../helper/ContactUsInfo";
import Moment from 'moment';
import DynamicObject from "../../models/dynamic-object";
import { PROMOTE } from "../helper/Ctas";
import {SocialUrlsValue, socialUrls} from "../helper/SocialUrlsInfo";
import { trackEvent } from "../../gtm/trackEvent";
import Api from "../../api/api";
import shortUrlApi from "../../api/site/shortUrl.api";
import { DEFAULT_AVATAR, NO_IMAGE, NO_IMAGE_SHOBIDAK } from "../helper/ImagePaths";
import { IMAGE_PREVIEW_SM } from "../helper/ImageSize";
import Auth from "opensooq-sso";  
import { logEditListingClick } from "../../logging/helpers/commonLoggingFunctions";
import getProfileApi from "../../api/profile/getProfile.api";
import { SessionDimensions } from "../../logging/dimensions/SessionDimensions";

const getDomainWithoutSubdomain = (url : string) => {
    const urlParts = new URL(url).hostname.split('.')
    return urlParts.slice(0).slice(-(urlParts.length === 4 ? 3 : 2)).join('.')
}

function preloadImage (src: string, stateSetter: (src: string)=>void) {
  return new Promise<string>((resolve, reject) => {
    const img = new Image()
    img.onload = function() {
      stateSetter(img.src)
    }
    img.onerror = img.onabort = function() {
      stateSetter(DEFAULT_AVATAR);
    }
    img.src = src
  })
}

const initiateLogout = async () => {
  const newAuth = Auth.getInstance();
  newAuth.init(
      getOriginDomain(),
      "sso",
      getCookies().NEXT_LOCALE,
      getCookies().ecountry,
      getCookies().source,
      getCookies().device_uuid,
      getCookies().cityId,
  );
  await Auth.getInstance().destroy();
}

const initiateLogin = async () => {
  const newAuth = Auth.getInstance();
  await newAuth.init(
    getOriginDomain(),
    "sso",
    getCookies().NEXT_LOCALE,
    getCookies().ecountry,
    getCookies().source,
    getCookies().device_uuid,
    getCookies().cityId,
  );  
  await Auth.getInstance().getToken(true,true);
}

const api401Handler = async () => {
  // await initiateLogout();
  await initiateLogin();
}

const logout = async () => {
  const newAuth = Auth.getInstance();
  newAuth.init(
      getOriginDomain(),
      "sso",
      getCookies().NEXT_LOCALE,
      getCookies().ecountry,
      getCookies().source,
      getCookies().device_uuid,
      getCookies().cityId,
  );
  await Auth.getInstance().destroy();
  // window.location.href = process.env.REACT_APP_OPENSOOQ_LOGOUT_URL?.replace("{country}",getCookies().ecountry ?? 'jo').replace("{lang}",getCookies().NEXT_LOCALE) ?? '';
}

const getSocialUrlsByCountry = (country:string): SocialUrlsValue => {
  return country in socialUrls ? socialUrls[country] : socialUrls['default'];
}

const getContactUsByCountry = (country : string) : ContactUsValue => {
  return country in contactUs ? contactUs[country]: contactUs['default'];
}

const redirectOut = (url : string) => {
  window.open(url, '_blank');
}

const generateOpenSooqRedirectUrl = (url ?: string,country ?: string,lang ?: string) => {
  let redirectTo = url?.replace("{country}", country ?? '').replace("{lang}", lang ?? '');
  if(isShobiddak()){
    redirectTo = url?.replace("{country}.", '').replace("/{lang}", '');
  }
  return redirectTo;
}

const redirectionInterceptor = ()=>{
  setCookie('utmSender', JSON.stringify({cSource:SessionDimensions.getInstance().sessionSource,cMedium:SessionDimensions.getInstance().sessionMedium,cName:SessionDimensions.getInstance().sessionCampaign}), defaultOptions)  
}

const formateDate = (date : string) => {
  return Moment.unix(parseInt(date)).format("DD-MM-YYYY hh:mm")
}

const canTakeAction = (ctas : DynamicObject,action : string) => {
  return ctas?.find((cta : DynamicObject) => {
    return cta.cta === action
  });
}

const takeAction = (cta : string,listingId : number) => {
  if(cta === PROMOTE){
  }
}

export type LiteUserInfo = {
  id: number;
  full_name: string;
  profile_picture: string;
  phone_number: string;
  member_type:number
  shopName?: string
  hasPassword?: boolean
};

export type AuthTicketType = {
  t: number;
  k: string;
};
  
export const jsonSafeGet = <T>(jsonString : any, defaultData = {}): T => {
  let output = defaultData as T;
  try {
    output = JSON.parse(jsonString);
  } catch (e) {
    // do nothing.
  }
  return output;
};

type CookiesDataTypes = {
  userInfo : LiteUserInfo
  tgtTimestamp : any
  tgtTicket : any
  ecountry :string
  source:string
  device_uuid:string
  NEXT_LOCALE:string
  VIEWED_LISTING_COOKIE:string
  audience : string
  cityId : string,
  lastContacted : any
}

export const isDeviceMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent || ""
  );
}

const getCookies = () => {
  const data : CookiesDataTypes  =  {
      userInfo: jsonSafeGet<LiteUserInfo>(Cookies.get('userInfo')),
      tgtTimestamp: jsonSafeGet<AuthTicketType>(Cookies.get('auth')).t || Cookies.get('tgt_timestamp'),
      tgtTicket: jsonSafeGet<AuthTicketType>(Cookies.get('auth')).k || Cookies.get('tgt_ticket'),
      ecountry: Cookies.get('ecountry') ?? '',
      source : Cookies.get('source') || Cookies.get('source') || (isDeviceMobile() ? 'mobile' : 'desktop'),
      device_uuid: Cookies.get('device_uuid') ?? '',
      NEXT_LOCALE:Cookies.get('NEXT_LOCALE') ?? 'ar',
      VIEWED_LISTING_COOKIE:Cookies.get('recentlyViewed') ?? 'recentlyViewed',
      audience:  Cookies.get('source') || (isDeviceMobile() ? 'mobile' : 'desktop'),
      cityId : Cookies.get('cityId') || '',
      lastContacted : Cookies.get("lastContacted") ? JSON.parse(Cookies.get("lastContacted")) : []
  }

  if(!Cookies.get('source')){
    setCookie('source',(isDeviceMobile() ? 'mobile' : 'desktop'),defaultOptions);
  }

  return data;
}

const checkIfLogin = () : boolean => {
  const appCookies : CookiesDataTypes = getCookies();
  if(appCookies.userInfo && appCookies.ecountry && appCookies.tgtTicket && appCookies.tgtTimestamp)
    return true;
  return false;
}

const defaultOptions = {
  expires: process.env.REACT_APP_DEFAULT_COOKIES_EXPIRED_IN_MINUTES,
  path: "/",
  ...(typeof window !== "undefined"
    ? window.location.hostname.includes("localhost")
      ? { domain: window.location.hostname }
      : {
          domain:
            "." +
            window.location.hostname.split(".").reverse()[1] +
            "." +
            window.location.hostname.split(".").reverse()[0],
        }
    : {}),
};


const setCookie = (name : string , value : string, options : DynamicObject)  => {
  // Default values for options:
  let { expires = "" , path = "/", domain = "" } = options;

  // If expires is a number, convert it to minutes and create a Date object:
  if (typeof expires === "number") {
    const minutes = expires;
    const now = new Date();
    now.setTime(now.getTime() + minutes * 60 * 1000);
    expires = now;
  }

  // Construct the cookie string:
  let cookieString = `${name}=${value}`;

  if (expires instanceof Date) {
    cookieString += `;expires=${expires.toUTCString()}`;
  }

  if (path) {
    cookieString += `;path=${path}`;
  }

  if (domain) {
    cookieString += `;domain=${domain}`;
  }

  // Set the cookie:
  document.cookie = cookieString;
}

const removeEmptyValues = (object :DynamicObject) => {
  for (var key in object) {
      if (object.hasOwnProperty(key)) {
          var value = object[key];
          if (value === null || value === undefined || value === '') {
              delete object[key];
          }
      }
  }
}

const deleteCookie = ( name : string) =>  {
  document.cookie = `${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`
}

const logError = (error : any,url : string, path:string ) => {
  const errorCode = (error.response && error.response.status ) ? error.response.status : 0
  if(errorCode != 0){
      const link = document.createElement('a');
      link.href = url;
      trackEvent(errorCode,'Error',path,url);
  }
}

const log404Error = (msg : string,errorType : string) => {
  return Api.getInstance().request.post(`logging/tech-log`,{
    level : errorType,
    category : "My OS 404 Error",
    msg : msg
  })
}

const shorten = async (data : DynamicObject) => {
  return shortUrlApi(data)
}

const getAddPostIntegrationUrl = (lang : string,type : string , id : number,draftId : number,iframe = false) => {
    const fromMyOs = (iframe) ? "&from=my-opensooq" : ""
    return `${process.env.REACT_APP_ADD_POST}?v=2&source=${getCookies().source}&lang=${lang}&flow=${type}&draft_id=${draftId}${fromMyOs}&post_id=${id}&return=${window.location.href}&${extractUtms()}`;
}

const setBackURLForAddPost = ()=>{
  setCookie("addPostRef",encodeURIComponent(window.location.href), defaultOptions);
}

const deleteCookieKey = (name : string) => {
  // Default values for options:
  let { expires = "" , path = "/", domain = "" } = defaultOptions;


  // Construct the cookie string:
  let cookieString = `${name}=`;

  // set expiry
  cookieString += `;expires=Thu, 01 Jan 1970 00:00:00 GMT`;

  if (path) {
    cookieString += `;path=${path}`;
  }

  if (domain) {
    cookieString += `;domain=${domain}`;
  }

  // Set the cookie:
  document.cookie = cookieString;
}

const editListing = (listing : DynamicObject,type : string) => {
    setCookie("addPostRef",encodeURIComponent(window.location.href), defaultOptions);
    if(type === 'edit')
    {
      logEditListingClick({
        id: listing.listing_id, 
        service: listing.service_name && listing.service_name.length > 0 ? listing.service_name[listing.service_name.length-1]?.icon : '', 
        category: { label: listing.category_reporting_name },
        city:{name: listing.city_label_en}, 
        country: listing.country_abbreviation, 
        neighborhoodName: listing.neighborhood_name_en ?? '',
        subCategory: listing.subcategory_reporting_name   
      }); 
    }
    setTimeout(()=>{
      window.location.href = getAddPostIntegrationUrl(getCookies().NEXT_LOCALE,type,listing.listing_id ?? 0,listing.draft_id ?? 0,false);
    }, 200)
}

const editCv = (cv : DynamicObject,type : string) => {
  setCookie("addPostRef",encodeURIComponent(window.location.href), defaultOptions);
  window.location.href = getAddPostIntegrationUrl(getCookies().NEXT_LOCALE,type,cv.equivalent_post_id ?? 0,cv.draft_id ?? 0,false);
}

const getNoImageLogo = (lang : string) => {
  let noImage = NO_IMAGE.replace('{lang}',lang);
  if(isShobiddak()){
    noImage =  NO_IMAGE_SHOBIDAK.replace('{lang}',lang);
  }
  return noImage;
}

const isShobiddak = () => {
  let isShowbidak = false;
  if(getOriginDomain() === process.env.REACT_APP_SHOBIDAK_DOMAIN){
    isShowbidak =  true;
  }
  return isShowbidak;
}

const getOriginDomain = () => {
  var hostname = window.location.hostname; // Get the hostname from the current URL
  var parts = hostname.split('.'); // Split the hostname by periods
  if (parts.length > 2) { // Check if there is a subdomain
    hostname = parts.slice(1).join('.'); // Remove the subdomain
  }
  return hostname;
}

const getSiteCustomContent= (Intl : any) => {
  let content = {
    viewOnSite : Intl.formatMessage({id : "common.viewOnOpenSooq"}),
    siteUrl : process.env.REACT_APP_MY_OPENSOOQ_DOMAIN,
    siteName : Intl.formatMessage({id : 'footer.openSooqName'}),
    aboutUsHeader : Intl.formatMessage({id : 'footer.openSooq'}),
    aboutUsContent : Intl.formatMessage({id : 'footer.aboutOpenSooq'}),
    mainPageTitle : Intl.formatMessage({id : 'myOpenSooq.title'}),
    backToSite : Intl.formatMessage({id : 'backTopOpenSooq'}),
    sitePlus : Intl.formatMessage({id : 'hidePromotionListingsText1'}),
    sitePlusDesc : Intl.formatMessage({id : 'products.openSooqBuyer.section2'}),
    backToSiteDomain : Intl.formatMessage({id : 'backToOpenSooqWebsite'}),
    serviceValue: Intl.formatMessage({id : 'products.ValueService'})
  }
  if(isShobiddak()){
    content = {
      viewOnSite : Intl.formatMessage({id : "common.viewOnShobiddak"}),
      siteUrl : process.env.REACT_APP_SHOBIDAK_DOMAIN,
      siteName : Intl.formatMessage({id : 'footer.shobiddakName'}),
      aboutUsHeader : Intl.formatMessage({id : 'footer.shobiddak'}),
      aboutUsContent : Intl.formatMessage({id : 'footer.aboutShobiddak'}),
      mainPageTitle : Intl.formatMessage({id : 'shobiddakPageTitle'}),
      backToSite : Intl.formatMessage({id : 'backToShobiddak'}),
      sitePlus : Intl.formatMessage({id : 'shobiddakPlus'}),
      sitePlusDesc : Intl.formatMessage({id : 'products.openSooqBuyer.section2'}),
      backToSiteDomain : Intl.formatMessage({id : 'backToShobiddakDomain'}),
      serviceValue: Intl.formatMessage({id : 'products.shobiddak'})
    }
  }
  return content;
}

const showChatInCountry = () : boolean =>{
  return (isCountryIncluded() && isDomainIncluded()) ? true : false;
}

const isCountryIncluded = ()=>{
  const ecountry = getCookies().ecountry
  const excludedCountriesString = process.env.REACT_APP_EXCLUDE_CHAT_FROM_COUNTRIES
  let excludedCountries : string[] = []
  let index = -1
  if(excludedCountriesString){
    excludedCountries = excludedCountriesString.split(',')
    index = excludedCountries.findIndex((country => country.toLowerCase() === ecountry.toLowerCase()))
  }
  return (index < 0) ? true : false;
}

const isDomainIncluded = () : boolean => {
  let currentDomain = window.location.hostname;
  let domainParts = currentDomain.split('.'); // Split the domain into parts
  if (domainParts.length > 2) {
    currentDomain = domainParts.slice(1).join('.');
  }
  const excludedDomainsString = process.env.REACT_APP_EXCLUDE_CHAT_FROM_DOMAINS
  let excludedDomains : string[] = []
  let index = -1
  if(excludedDomainsString){
    excludedDomains = excludedDomainsString.split(',')
    index = excludedDomains.findIndex((country => country.toLowerCase() === currentDomain.toLowerCase()))
  }
  return index < 0 ? true : false;
}

const carseerAvailable = () => {
  const countriesStr : string = process.env.REACT_APP_CARSEER_COUNTRIES ?? '';
  const countries : string[] = countriesStr.split(',');
  let index : number = countries.findIndex((country) =>  country.toLowerCase() === getCookies().ecountry.toLowerCase())
  return index !== -1;
}

const changePassword = async (newPassword : string)=>{
    return Auth.getInstance().changePassword(newPassword)
}


let sharedTokenPromise = null;

const generateToken = async () => {
  if (!sharedTokenPromise) {
    // If no shared promise exists, create one
    sharedTokenPromise = (async () => {
      const token = await Auth.getInstance().getToken(true, true);
      sharedTokenPromise = null;
      return token;
    })();
  }
  return sharedTokenPromise;
}

const clearSharedTokenPromise = (wwwww?) => {
  sharedTokenPromise = null;
}

const getImage = (imageName : string) => {
  return (imageName && imageName.length == 12 && imageName.charAt(0) == 'u') ? DEFAULT_AVATAR : `${process.env.REACT_APP_ARBOK_URL + IMAGE_PREVIEW_SM}/` + imageName + '.webp'
}

const getApplicantImage = (imageName : string) => {
  if(!imageName)
    return DEFAULT_AVATAR;
  return (imageName && imageName.length == 12 && imageName.charAt(0) == 'u') ? DEFAULT_AVATAR : `${imageName.replace("{platform}","android").replace("{size}","xxh")}`
}

const getGeneralImage = (imageName : string) => {
  if(!imageName)
    return DEFAULT_AVATAR;
  return (imageName && imageName.length == 12 && imageName.charAt(0) == 'u') ? DEFAULT_AVATAR : `${imageName.replace("{platform}","android").replace("{size}","xxh")}`
}

const getCvImage = (imageName : string) => {
  return (imageName && imageName.length == 12 && imageName.charAt(0) == 'u') ? DEFAULT_AVATAR : `${imageName.replace("{platform}","android").replace("{size}","xxh")}`
}

const getReportImage = (imageName : string) => {
  return (imageName && imageName.length == 12 && imageName.charAt(0) == 'u') ? DEFAULT_AVATAR : `${imageName.replace("{platform}","android").replace("{size}","xxh")}`
}

const getCtaName = (object : DynamicObject,ctaName : string) => {
  let name = '';
  if(object && object.ctas){
    object.ctas.map((cta : DynamicObject,index : number) => {
          if(cta.cta == ctaName){
            name = cta.label
          }
      });
  }
  return name
}

const isEmailVerified = (memberVerification) => {
    if (memberVerification & 1) {
      return true;
    }
    return false;
}

const isValidEmail = (email : string) => {
  if (email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
    return true;
  }
  return false;
}

const cvLabelAligner = (matchLabel: string)=>{
  const reg = /^\d{1,3}%/g;
  const matchValue = matchLabel.match(reg);
  if(matchValue && matchValue.length > 0)
      return matchLabel.replace(matchValue[0], '') + ' \u200E'+matchValue[0]+'\u200E';
  return matchLabel;
}

const getProfileImage = ()=>{
  return '/media/avatar/profile_avatar.png';
}

let profilePromise = null;

const getProfileFunction = async () => {
  if (!profilePromise) {
    profilePromise = (async () => {
      const response = await getProfileApi()
      profilePromise = null;
      return response;
    })();
  }
  return profilePromise;
}

const hasNoPassword = () => {  
  if(!getCookies().userInfo || (getCookies().userInfo && !getCookies().userInfo?.hasPassword)){
    return true;
  }
  return false;
}

const generateDateRangeObject = (min, max, free, premium) => {
  const minDate = new Date(min); 
  const maxDate = new Date(max);
  const series = {
    premium: [],
    free: [],
    singlePremiumCat: [],
    singleFreeCat: [],
    cat: [],
    catModified : []
  };
  for (let d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
    let timestamp = Math.floor(d.getTime() / 1000); 
    const newtimestamp = Number(d); 
    const maxDate = new Date(newtimestamp);
    let newmax  : any= maxDate.getTime();
    newmax = new Date(newmax * 1000)
    const yyyy = maxDate.getFullYear();
    const mm = (maxDate.getMonth() + 1).toString().padStart(2, '0');
    const dd = maxDate.getDate().toString().padStart(2, '0');
    const maxformattedDate = `${yyyy}-${mm}-${dd}`;
    series.premium.push({x : new Date(maxformattedDate).getTime() , y : premium[timestamp] || 0});
    series.free.push({x : new Date(maxformattedDate).getTime() , y : free[timestamp] || 0});
    series.singlePremiumCat.push(premium[timestamp] || 0);
    series.singleFreeCat.push( free[timestamp] || 0);
    series.cat.push(Moment.unix(timestamp).format("MM/DD"));
    series.catModified.push(Moment.unix(timestamp).format("MM/DD"));
  }

  if(series.cat.length == 1){
    series.singlePremiumCat = [0, 0, 0].concat(series.singlePremiumCat).concat([0, 0, 0]);
    series.singleFreeCat = [0, 0, 0].concat(series.singleFreeCat).concat([0, 0, 0]);

    // Get the current year dynamically
    let currentYear = new Date().getFullYear();

    // Parse the date string using the current year
    let baseDate = new Date(`${currentYear}/${series.cat[0]}`);

    // Array to hold the dates
    let dates = [];

    // Loop to get dates 3 days before to 3 days after
    for (let i = -3; i <= 3; i++) {
      let tempDate = new Date(baseDate);
      tempDate.setDate(baseDate.getDate() + i);
      dates.push(formatDate(tempDate));
    }

    series.catModified = dates;
  }
  
  return series;
};

const generateAccountStatsDateRangeObject = (min, max, stats) => {
  const minDate = new Date(min); 
  const maxDate = new Date(max);
  const series = {
    stats: [],
    cat: [],
  };
  for (let d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
    let timestamp = Math.floor(d.getTime() / 1000); 
    const newtimestamp = Number(d); 
    const maxDate = new Date(newtimestamp);
    let newmax  : any= maxDate.getTime();
    newmax = new Date(newmax * 1000)
    const yyyy = maxDate.getFullYear();
    const mm = (maxDate.getMonth() + 1).toString().padStart(2, '0');
    const dd = maxDate.getDate().toString().padStart(2, '0');
    const maxformattedDate = `${yyyy}-${mm}-${dd}`;
    series.stats.push({x : new Date(maxformattedDate).getTime() , y : stats[timestamp] || 0});
    series.cat.push(Moment.unix(timestamp).format("MM/DD"));
  }

  return series;
};

const  formatDate = (date : any) => {
  let month = ('0' + (date.getMonth() + 1)).slice(-2); // Get month and add leading zero if needed
  let day = ('0' + date.getDate()).slice(-2); // Get day and add leading zero if needed
  return `${month}/${day}`;
}

const extractUtms = ()=>{
  const cSource = SessionDimensions.getInstance().sessionSource;
  const cCampaign = SessionDimensions.getInstance().sessionCampaign;
  const cMedium = SessionDimensions.getInstance().sessionMedium;
  return `cSource=${cSource}&cName=${cCampaign}&cMedium=${cMedium}`;
}

const footerLinksMapper = {
  "support": {
    ar: 'مساعدة',
    en: 'support'
  },
  "contact-us": {
    ar: 'مساعدة/اتصل-بنا',
    en: 'support/contact-us'
  },
  "sales-representatives": {
    ar: 'مندوبي-المبيعات',
    en: 'sales-representatives'
  },
  "term-of-use": {
    ar: 'termOfUse',
    en: 'termOfUse'
  },
  "privacy-policy": {
    ar: 'site/page?view=privacyPolicy',
    en: 'site/page?view=privacyPolicy'
  },
  "safety-tips": {
    ar: 'قواعد-السلامة',
    en: 'safety-tips'
  },
  "other-countries": {
    ar: "ref=change_country_jo",
    en: "ref=change_country_jo"
  },
  "site-map":{
    ar: 'site-map',
    en: 'site-map'
  },
  "our-products":{
    ar: "الخدمات-الاعلانية",
    en: "advertising-products"
  }
}

export const keepOnlyNumbers = (input: string): string => {
  return input.replace(/\D/g, '');
}


const deepLinks = {
  help:{
    "ar": "xx/help",
    "en": "xx/help"
  },
  contactUs:{
    "ar": "xx/contactUS",
    "en": "xx/contactUS"
  },
}

const deepLinkHandler = (deepLink: string) => {
  const requestUri = deepLink.replace("xx", getCookies().ecountry);
  const deepLinkBase =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry, getCookies().NEXT_LOCALE) + '/';
  const url = `${deepLinkBase}site/dl?requestUri=${requestUri}`;
  window.open(url,'_blank');
}

export type {CookiesDataTypes}
export {footerLinksMapper, getProfileImage,setBackURLForAddPost,getProfileFunction,preloadImage, isValidEmail,isEmailVerified,getReportImage,getCvImage,getCtaName,editCv,getApplicantImage,getImage,api401Handler,carseerAvailable,getOriginDomain,changePassword,generateToken,isShobiddak,getNoImageLogo,getSiteCustomContent,editListing,getAddPostIntegrationUrl,shorten,log404Error,logError,deleteCookie,removeEmptyValues,generateOpenSooqRedirectUrl,getDomainWithoutSubdomain,logout,getContactUsByCountry,getSocialUrlsByCountry,redirectOut,formateDate,canTakeAction,takeAction,checkIfLogin,getCookies,defaultOptions,setCookie, showChatInCountry, cvLabelAligner, clearSharedTokenPromise,getGeneralImage, hasNoPassword, deleteCookieKey, extractUtms, redirectionInterceptor,generateDateRangeObject,generateAccountStatsDateRangeObject, deepLinkHandler, deepLinks}
