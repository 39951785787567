import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { extractUtms, logout } from '../helpers/commonFunctions';
import { PageTitle } from "../layout/core";
import { IframeLoader } from './common/IframeLoader';

export function RequestFeature() {
    const iframeContent = useRef<null | HTMLIFrameElement>(null);
    const [loader,setLoader] = useState <boolean>(true);
    const Intl = useIntl();

    window.addEventListener("message", event => {
      if(event.data === 401){
          logout()
      }else if(event.data == 200){
          setLoader(false);
          if(iframeContent.current){
              iframeContent.current.classList.remove('d-none')
          }
      }}, false);
  
  return  (
    <> <PageTitle breadcrumbs={[]}>{Intl.formatMessage({id : "requestFeature"})}</PageTitle>
        <div className="row justify-content-center">
            <div className="col-lg-12 col-12">
                <div className='text-center' >
                    {loader && <IframeLoader/>}
                    <iframe src={`${process.env.REACT_APP_ADD_POST}?flow=requestFeature&feature-type=requestFeature&${extractUtms()}`} className="d-none w-100" style={{height : "100vh",overflowY:"hidden"}} ref={iframeContent}></iframe>
                </div>
            </div>
        </div></>
    );
}
