/* eslint-disable jsx-a11y/anchor-is-valid */
import Auth from "opensooq-sso";
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { deleteCookie, deleteCookieKey, getCookies, getOriginDomain } from '../../../helpers/commonFunctions';

type props = {
  comingFrom?: string
  dataId: string
}

const Logout = ({comingFrom, dataId}:props) => {
  const Intl = useIntl();
  useEffect(() => {
    const newAuth = Auth.getInstance();
    newAuth.init(
        getOriginDomain,
        "sso",
        getCookies().NEXT_LOCALE,
        getCookies().ecountry,
        getCookies().source,
        getCookies().device_uuid,
        getCookies().cityId
    );
  },[])

  const logout = async () => {
    deleteCookieKey('lastContacted');
    setTimeout( async () =>{ 
      await Auth.getInstance().destroy();
      window.location.href = process.env.REACT_APP_OPENSOOQ_LOGOUT_URL?.replace("{country}",getCookies().ecountry ?? 'jo').replace("{lang}",getCookies().NEXT_LOCALE) ?? '';
    },300);
  }
  return (
    <div  className={`menu-item ${comingFrom === 'headMenu'? 'dropdown-item fw-bold':''}`} data-id={`${dataId}${comingFrom?'-'+comingFrom: ''}`}>
      <span className='menu-link' onClick={logout}>
        <span className='menu-icon'>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-flip="true" className="inline vMiddle"><path d="M17 8L15.59 9.41L17.17 11H9V13H17.17L15.59 14.58L17 16L21 12L17 8ZM5 5H12V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H12V19H5V5Z" fill="#000"></path></svg>
        </span>
        <span className='menu-title position-relative fs-16'>
          {Intl.formatMessage({id: 'logout'})}
        </span>
      </span>
    </div>
  )
}

export { Logout };
