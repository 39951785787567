
// @ts-nocheck

import axios from "axios";
import { api401Handler, extractUtms, generateToken, getCookies, logError } from "../app/helpers/commonFunctions";
import { SessionDimensions } from "../logging/dimensions/SessionDimensions";

interface IRequest {
    get: (url: string, path?:string) => Promise < any > 
    delete: (url: string, path?:string) => Promise < any > 
    post: (url: string, body: any, path?:string) => Promise < any >
    put: (url: string, body: any, path?:string) => Promise < any >
}

interface IDefaultHeaders {
    source: string;
    'accept-language': string;
    'session-id': number;
    country: string;
    'x-tracking-uuid': string;
    'Content-Type': string;
    'enforce-language': string
}

export default class Api {
    private static async getHeaders(defaultHeaders : IDefaultHeaders) : any{
        const token = await generateToken()
        const headers = {
            ...(token ? {
                'Authorization': 'Bearer ' + token
            } : {}),
            ...defaultHeaders,
        };
        return headers;
    }

    private static urlInterceptor(url: string): string{
        const hasParams = (url.indexOf('?') !== -1);
        return `${url}${hasParams ? '&' : '?'}${extractUtms()}`;
    }

    static getInstance(otherSource : boolean = false,isBase ?: boolean = false,customUrl = '',passCountry = true,logging = false):{request:IRequest} {
        let baseUrl = (isBase) ? process.env.REACT_APP_OPENSOOQ_API_URL_BASE : !otherSource ? process.env.REACT_APP_OPENSOOQ_API_URL : process.env.REACT_APP_OPENSOOQ_API_URL_VERTICAL
        
        if(customUrl != ''){
            baseUrl = customUrl
        }

        if(logging){
            baseUrl = `${process.env.REACT_APP_NEXT_PUBLIC_LOGGING_URL_CLIENT}`;
        }

        const originalAxios = axios.create({
            baseURL: baseUrl,
            timeout: Number(process.env.REACT_APP_API_TIMEOUT || '5000')
        });

        let lang = 'ar';
        if(localStorage.getItem('i18nConfig')){
            lang =  localStorage.getItem('i18nConfig').indexOf('ar') > -1 ? 'ar' : 'en'
        }

        const cookiesData = getCookies();
        const defaultHeaders = {
            'source':  cookiesData.source,
            'accept-language': cookiesData.NEXT_LOCALE,
            'session-id' : cookiesData.userInfo.id,
            'country': passCountry ? cookiesData.ecountry : '',
            'x-tracking-uuid':cookiesData.device_uuid,
            'Content-Type' : 'application/json',
            'enforce-language': cookiesData.NEXT_LOCALE
        };

        const request: IRequest = {
            get: async function (url: string, path?:string = "") {
                url = Api.urlInterceptor(url)
                return originalAxios.get(url, {
                    headers: await Api.getHeaders(defaultHeaders)
                }).catch(error => {
                    logError(error,url, path)
                    if(error.response && error.response.status === 401){
                        api401Handler()
                        return Api.getInstance().request.get(url,path)
                    }
                    throw error;
                });
            },
            delete: async function (url: string, path?:string = "") {
                url = Api.urlInterceptor(url)
                return originalAxios.delete(url, {
                    headers: await Api.getHeaders(defaultHeaders)
                }).catch(error => {
                    logError(error,url, path)
                    if(error.response && error.response.status === 401){
                        api401Handler()
                        return Api.getInstance().request.delete(url,path)
                    } 
                    throw error;
                });
            },
            post: async function (url: string, data:any, path?: string = "") {
                url = Api.urlInterceptor(url)
                const response = await originalAxios.post(url, data,{
                    headers: await Api.getHeaders(defaultHeaders)
                }).catch(error => {
                    logError(error, url, path)
                    if(error.response && error.response.status === 401){
                        api401Handler()
                        return Api.getInstance().request.post(url,data,path)
                    } 
                    throw error;
                });

                return response;
            },
            put: async function (url: string, data: any, path? :string ="") {
                url = Api.urlInterceptor(url)
                const response = await originalAxios.put(url, data,{
                    headers: await Api.getHeaders(defaultHeaders)
                }).catch(error => {
                    logError(error,url, path)
                    if(error.response && error.response.status === 401){
                        api401Handler()
                        return Api.getInstance().request.put(url,data,path)
                    } 
                    throw error;
                });

                return response;
            },
        }
        return {
            request
        }
    }


}