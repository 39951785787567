import { useRef, useState } from "react";
import { extractUtms, getCookies, logout } from "../../../helpers/commonFunctions";
import { useLang } from "../../../i18n/OpenSooqi18n";
import { IframeLoader } from "../../common/IframeLoader";

export function NewCv() {
    const iframeContent = useRef<null | HTMLIFrameElement>(null);
    const [loader,setLoader] = useState <boolean>(true)
    const lang = useLang();
    window.addEventListener("message", event => {
      if(event.data === 401){
          logout()
      }else if(event.data == 200){
          setLoader(false);
          if(iframeContent.current){
              iframeContent.current.classList.remove('d-none')
          }
      }
    }, false);
    const  randomNumberInRange = (min : number, max : number)  => {
        // 👇️ get number between min (inclusive) and max (inclusive)
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    
    return <div className="row justify-content-center">
        <div className="col-lg-8 col-12">
            <div className="card">
                <div className="card-body">
                    <div className='text-center' >
                        {loader && <IframeLoader/>}
                        <iframe key={randomNumberInRange(10000,999)} src={`${process.env.REACT_APP_ADD_POST}?v=2&source=${getCookies().source}&lang=${lang}&flow=my-cv&from=my-opensooq&${extractUtms()}`} className="d-none w-100" style={{height : "100vh",overflowY:"hidden"}} ref={iframeContent}></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
}