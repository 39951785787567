/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Rating as RatingStars } from 'react-simple-star-rating';
import getRatingApi from '../../../api/account/getRating.api';
import getReviewsApi from '../../../api/getReviews.api';
import { toAbsoluteUrl } from '../../../app/helpers';
import { RatingComponent } from '../../../app/layout/components/opensooq/RatingComponent';
import { RatingLoader } from '../../../app/layout/components/opensooq/RatingLoader';
import { ReviewComponent } from '../../../app/layout/components/opensooq/ReviewComponent';
import { getCookies } from '../../helpers/commonFunctions';

export interface RatingInterface {
    average_star            :   number
    n_star_1_percentage     :   number
    n_star_2                :   number
    n_star_2_percentage     :   number
    n_star_3                :   number
    n_star_3_percentage     :   number
    n_star_4                :   number
    n_star_4_percentage     :   number
    n_star_5                :   number
    n_star_5_percentage     :   number
    total                   :   number
}

export interface TagsInterface {
    formatCount             :   number
    iconUri                 :   string
    label_ar                :   string
    label_en                :   string
    order                   :   number
    label                   :   string
}

export interface ReviewsInterface {
    id          :   number
    star_id     :   number
    text        :   string
    member      :   MemberInterface
    created_at  :   string
}


export interface MemberInterface {
    avatar      :   string
    name        :   string
    text        :   string
    user_name   :   string
}

export function Rating() {
    const Intl = useIntl();
    const [rating,setRating] = useState <RatingInterface>()
    const [showLoader,setShowLoader] = useState (true)
    const [tags,setTags] =  useState <TagsInterface[]>([])
    const [reviews,setReviews] =  useState <ReviewsInterface[]>([])
    const ratingObject = [
        {
            status : false,
        },{
            status : false,
        },{
            status : false,
        },{
            status : false,
        },{
            status : false,
        }
    ]

    useEffect(() => {
        getRating(getCookies().userInfo.id ?? 0);
    },[]);

    function getRating(memberId : any){
        getRatingApi(memberId).then(response => {
            const rating = response.data.stats;
            const tags = response.data.tags;
            const reviews = response.data.reviews.items;

            tags.sort(function(a : any, b : any) {
                return a.order > b.order;
            });

            setRating(rating);
            setTags(tags);
            setReviews(reviews);
            setShowLoader(false);
        });

        getReviewsApi(memberId).then(response => {
            const reviews = response.data.items;
            setReviews(reviews);
        });
    }

    const list = () => {
        return tags.map((tag: TagsInterface,index) => {
          return <div className='col-lg-3 col-6 mt-3  col-md-4'  key={index} >
                <div className="d-flex align-items-center mb-8">
                    <div className="symbol symbol-50px me-5">
                        <span className="symbol-label bg-white">
                            <img src={`${process.env.REACT_APP_OPENSOOQ_CDN_URL}${tag.iconUri}`} className="h-100 align-self-center" alt={tag.label}/> 
                        </span>
                    </div>
                    <div className="flex-grow-1">
                        {tag.label}
                    </div>
                    <span className="badge badge-light-success fs-8 fw-bold">{tag.formatCount}</span>
                </div>
            </div>
        });
    };

    function dateToDMY(date : any) {
        var d = date.getDate();
        var m = date.getMonth() + 1; //Month from 0 to 11
        var y = date.getFullYear();
        return (d <= 9 ? '0' + d : d) + '-' + (m<=9 ? '0' + m : m) + '-' +  y;
    }

    const reviewsList = () => {
        return reviews.map((review: ReviewsInterface,index) => {
        const date = dateToDMY(new Date(review.created_at))
        return  <div className='col-12 mt-5'  key={index}>
                <ReviewComponent name={review.member.name} avatar={review.member.avatar} text={review.text} rating={review.star_id} date={date}/>
            </div>
        });
    };


  return (
    <>
        {showLoader && <><RatingLoader/> </>}
        {!showLoader && <><div className='card '>
            <div className="card-header">
                <div className='card-title'>
                    <h3>{Intl.formatMessage({id :'myRating'})}</h3>
                </div>
            </div>
            <div className='card-body p-5 '>
                <div className='row mt-3' data-id="my-ratings-rating">
                    {(rating && rating.average_star > 0) ?  <>  <div className='col-lg-4 col-12 text-center p-5' style={{margin : "auto"}}>
                            <div className="d-flex flex-column bd-highlight mb-3 p-5">
                                <div className=" "><span style={{fontSize : "40px",fontWeight : "700",lineHeight : "47px"}}>{rating?.average_star.toFixed(1)}</span></div>
                                <div className="rating justify-content-center">
                                    <RatingStars readonly={true} iconsCount={5} initialValue={rating?.average_star} allowFraction={true} fillStyle={{color:"rgb(255, 173, 15)"}}/>
                                </div>
                                {/* <div className='mt-2' style={{fontSize : "22px",lineHeight : "25px"}}>(<span>{rating?.total} {Intl.formatMessage({id: 'rate'})})</span></div> */}
                                <div className='mt-2' style={{fontSize : "22px",lineHeight : "25px"}}>(<span>{rating?.total})</span></div>
                            </div >
                        </div>
                        <div className='col-lg-8 col-12  p-5'>
                            <div className="d-flex flex-column bd-highlight mb-3 p-5">
                                <RatingComponent ratingNumber={5} percentage={rating?.n_star_5_percentage}/>
                                <RatingComponent ratingNumber={4} percentage={rating?.n_star_4_percentage}/>
                                <RatingComponent ratingNumber={3} percentage={rating?.n_star_3_percentage}/>
                                <RatingComponent ratingNumber={2} percentage={rating?.n_star_2_percentage}/>
                                <RatingComponent ratingNumber={1} percentage={rating?.n_star_1_percentage}/>
                            </div>
                        </div>
                    </> : ''}
                    {rating && rating?.average_star == 0 && <>
                        <div className="text-center">
                            <h2>{Intl.formatMessage({id : "rating.noRatings"})}</h2>
                            <img className="" width="130" height="130" src={toAbsoluteUrl('/media/custom/noRatings.svg')}/>
                        </div>
                    </>}
                </div>
            </div>
            </div>
            <div className='card mt-5'>
                <div className='card-header'>
                    <div className='card-title'>
                        <h3>{Intl.formatMessage({id: 'ratingText1'})}</h3>
                    </div>
                </div>
                <div className='card-body p-5 '>
                    <div className='row pb-5' data-id="my-ratings-reviewBadges">
                        {(tags.length > 0 ) ? list() : <>
                            <div className='col-12 text-center p-5'>
                                <h2>{Intl.formatMessage({id : "rating.noReviewBadges"})}</h2>
                                <img className="" width="130" height="130" src={toAbsoluteUrl('/media/custom/badge.svg')}/>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
            <div className='card mt-5'>
                <div className='card-header'>
                    <div className='card-title'>
                        <h3>{Intl.formatMessage({id: 'rate'})} {(reviews.length > 0) ? `(${reviews.length})` : ''}</h3>
                    </div>
                </div>
                <div className='card-body p-0 '>
                    <div className='row p-5' data-id="my-ratings-reviews">
                        {(reviews.length > 0 ) ? reviewsList() : <>
                            <div className='col-12 text-center p-5'>
                                <h2>{Intl.formatMessage({id : "rating.noReviews"})}</h2>
                                <img className="" width="130" height="130" src={toAbsoluteUrl('/media/custom/no-reviews.svg')}/>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </>}
    </>
  )
}
